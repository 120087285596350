import {createStore} from 'vuex'

import {auth} from './modules/auth'
import navigation from './modules/navigation'
import order from './modules/order'
import product from './modules/product'
import cart from './modules/cart'

export default createStore({
  state: {
    isLoading: false,
    error: null,
    success: null
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.isLoading = loading
    },
    SET_ERROR(state, error) {
      state.error = error
    },
  },
  actions: {},
  modules: {
    auth,
    navigation,
    order,
    product,
    cart
  }
})
