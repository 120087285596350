export const NODE_ENV = `${import.meta.env.MODE}`

export const ENTRYPOINT = window.lingokingConfig.API_URL
export const METABASE_SECRET_KEY = window.lingokingConfig.METABASE_SECRET_KEY
export const METABASE_SITE_URL = window.lingokingConfig.METABASE_SITE_URL
export const LOGIN_PAGE = window.lingokingConfig.SHOP_SERVICE_URL + '/login'
export const SHOP_SERVICE_URL = window.lingokingConfig.SHOP_SERVICE_URL
export const SHOP_URL = window.lingokingConfig.SHOP_URL
export const PAYPAL_ENV = window.lingokingConfig.PAYPAL_ENV

export const sentryEntrypoint = {
  ENTRYPOINT,
  SENTRY_ENV: window.lingokingConfig.SENTRY_ENV,
  SENTRY_RELEASE: window.lingokingConfig.SENTRY_RELEASE,
  SENTRY_DSN: window.lingokingConfig.SENTRY_DSN_CONFIGURATOR,
  SENTRY_SAMPLERATE: parseFloat(window.lingokingConfig.SENTRY_SAMPLERATE)
}
