const DeliveryTime = {
  email_delivery_method_standard: [3, 5],
  email_delivery_method_express: [2, 3],
  delivery_certified_scan_only: [1],
  delivery_method_standard: [3, 5],
  delivery_method_standard_no_tracking: [3, 5],
  delivery_method_express_no_tracking: [2, 3],
  ba_delivery_method_standard: [10],
  ba_delivery_method_express: [7],
  ba_delivery_method_email: [10],
  ba_delivery_method_email_express: [7],
  delivery_method_express: [2, 3],
  delivery_method_worldwide: [3, 5],
  email_delivery_method_express_hour: [0, 1],
  email_delivery_method_standard_hour: [0, 1],
  b2b_certified_physical_delivery_plus_scan: [2, 3],
}

export default DeliveryTime
