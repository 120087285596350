export function captureOrderRemovedFromCart(data) {
  capture("OrderRemovedFromCart", data);
}

export function captureOrderAddedToCart(data) {
  capture("OrderAddedToCart", data);
}

export function capturePurchaseCompleted(data) {
  capture("PurchaseCompleted", data);
}

export function captureUserInteraction(data) {
  capture("UserInteraction", data);
}

export function captureEcommerceEventByOrder(data) {
  capture("EcommerceEventByOrder", data);
}

export function captureTrackEcommerceCartUpdateByInvoice(data) {
  capture("TrackEcommerceCartUpdateByInvoice", data);
}

export function captureTrackEcommerceOrderByInvoice(data) {
  capture("TrackEcommerceOrderByInvoice", data);
}

export function captureUserEntersCheckout(data) {
  capture("UserEntersCheckout", data);
}

export function captureUserEntersOrderList(data) {
  capture("UserEntersOrderList", data);
}

export function captureSuccessLogin(data) {
  capture("SuccessLogin", data);
}

export function captureSuccessRegistration(data) {
  capture("SuccessRegistration", data);
}

export function captureProductPageView(data) {
  capture("ProductPageView", data);
}

export function captureNotFoundView() {
  capture("not_found");
}

function capture(eventName, data) {
  if (!window.posthog) {
    console.error("Posthog instance not found", { eventName, data });
    return;
  }

  window.posthog.capture(eventName, data);
}

export default {
  captureOrderAddedToCart,
  captureOrderRemovedFromCart,
  capturePurchaseCompleted,
  captureUserInteraction,
  captureUserEntersCheckout,
  captureUserEntersOrderList,
  captureSuccessLogin,
  captureSuccessRegistration,
  captureEcommerceEventByOrder,
  captureTrackEcommerceCartUpdateByInvoice,
  captureTrackEcommerceOrderByInvoice,
  captureProductPageView,
  captureNotFoundView,
};
