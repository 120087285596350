<template>
  <span class="iconlk-info cursor-pointer text-[24px]" @click="confirmation()"></span>
</template>

<script>
import { useConfirm } from 'primevue/useconfirm'

import useImage from '../../composables/useImage'

export default {
  name: 'HowToTipModal',
  props: ['howToTips', 'warn'],
  emits: ['toggle-modal', 'set-preselected-option'],
  setup(props, emits) {
    const confirm = useConfirm()
    const { getImageURL } = useImage()

    const confirmation = () => {
      confirm.require({
        message: props.howToTips.text,
        header: props.howToTips.headline,
        acceptLabel: props.howToTips.acceptButtonLabel,
        rejectLabel: props.howToTips.rejectButtonLabel,
        accept: () => {
          togglePreSelectedOption()
        }
      })
    }

    const togglePreSelectedOption = () => {
      setTimeout(function () {
        emits.emit('set-preselected-option')
      }, 500)
    }

    return {
      confirmation,
      getImageURL,
      togglePreSelectedOption
    }
  }
}
</script>

<style scoped></style>
