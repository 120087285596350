import api from "./api";
import CookieService from './cookie'
import TokenService from "./token";

class AuthService {
  login({email, password}) {
    return api
      .post("/login_check", {
        email,
        password
      })
      .then((response) => {
        if (response.data.token) {
          TokenService.setAccessToken(response.data);
        }

        return TokenService.getTokenData();
      })
      .catch(reason => {
        CookieService.removeAllCookies()

        return Promise.reject(reason)
      });
  }

  logout() {
    return api
      .get("/logout")
      .then((response) => {
        CookieService.removeAllCookies()

        return response;
      });
  }

  profile() {
    return api
      .get("/user-profile")
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch(reason => {
        return Promise.reject(reason)
      });
  }

  refresh() {
    return api
      .post("/token/refresh", {
        'refresh_token': TokenService.getLocalRefreshToken()
      })
      .then((response) => {
        if (response.data.token) {
          TokenService.updateLocalAccessToken(response.data);
        }

        return response;
      })
      .catch(reason => {
        CookieService.removeAllCookies()

        return Promise.reject(reason)
      });
  }

  register(customer) {
    return api.post("/customers/register", customer);
  }

  update(customer) {
    return api.put("/customers/" + customer.id, customer);
  }

  loadAuthorization(values) {
    return api.get('/users/' + values.uid + '/authorization?productIds=' + values.productIds);
  }

  loadUserData(customerId) {
    return api.get('/users/' + customerId + '?groups[]=address:read');
  }
}

export default new AuthService();
