export default {
  translations_for_every_need: 'Translations for every need.',
  login_page_subtitle: 'Welcome to lingoking - your platform for translations of all kinds! Login now and carry on with your orders or start new ones.',
  home_page_subtitle: 'Welcome back to lingoking. In your dashboard you will find an overview of all your orders and open tasks.',
  my_order_page_subtitle: "Here you will find an overview over all your orders, including their status.",
  my_invoice_page_subtitle: "Here you will find an overview over all your invoices. You can easily download them after the order is finished.",
  new_translation_subtitle: 'Search for any translation you desire and start the ordering process - easily and quickly.',
  greetings: "Hello",
  see_all: "See all",
  translations_in_progress: "Translations in Progress",
  completed_translations_in: "Completed Translations in {currentYear}",
  your_tasks: "Your Tasks",
  order_again: "Order Again",
  recommendations: "Recommendations",
  chats: "Chats",
  page_not_found: 'Page not found',
  page_not_found_description: 'The page you are looking for doesn\'t exist. Here are some helpful links',
  no_open_tasks_found: 'You have no open tasks – you can lay back!',
  products_not_found: 'Products not found',
  its_now_our_turn_asap: 'It’s now our turn to make sure you’ll get your translation as soon as possible.',
  you_have_no_chats_yet: 'You have no chats yet.',
  we_our_contact_open_questions: 'We or our translators will contact you in case of any open questions.',
  your_custom_translation_products: 'Your custom translation products',
  your_company_custom_translation_products: "Customized products for your company",
  custom: 'Customer',
  my_orders: 'My Orders',
  cartStatuses: {
    processing: "Processing",
    open: "Open",
    quote: "Quote",
    finished: "Finished",
    cancelled: "Canceled"
  },
  orderStatuses :{
    draft: 'draft',
    setup: 'setup',
    processing: 'processing',
    hold: 'hold',
    delivered: 'delivered',
    cancelled: 'canceled',
    deleted: 'deleted'
  },
  orderSortOptions: {
    newest_activity: "Newest activity",
    newest_order_date: "Newest order date",
    oldest_order_date: "Oldest order date",
  },
  orderStatusOptions: {
    draft: "Draft",
    setup: "Setup",
    processing: "Processing",
    hold: "Hold",
    delivered: "Delivered",
    canceled: "Canceled",
    deleted: "Deleted",
  },
  invoiceSortOptions: {
    date: 'Date',
    payment_state: 'Payment state',
  },
  invoiceStatuses: {
    draft: 'Draft',
    open: 'Open',
    paid: 'Paid',
    void: 'Void',
    uncollectible: 'Uncollectible',
    deleted: 'Deleted',
    settled: 'Settled'
  },
  jobstatuses: {
    draft: 'Draft',
    recruiting: 'Recruiting',
    queued: 'Queued',
    realization: 'Realization',
    finished: 'Finished',
    collected: 'Collected',
    paid: 'Paid',
    cancelled: 'Cancelled',
    deleted: 'Deleted',
  },
  jobDefinitionTypes: {
    translator: 'Translator',
    corrector: 'Corrector',
    final_revision: 'Final revision',
    qm: 'qm',
    final_revision_qm: 'Final revision qm',
    customer_approval: 'Customer-approval',
    order_adjustment: 'Order-Adjustment',
    printing: 'Printing',
    shipment: 'Shipment',
    confirmation: 'Confirmation',
    xrechnung_validation: 'Xrechnung validation',
    file_upload: 'File Upload'
  },
  validationErrorMessages: {
    user_type_required: 'User type is required field',
    first_name_required: 'First name is required',
    last_name_required: 'Last name is required',
    email_required: 'E-mail is required',
    timezone_required: 'Timezone is required',
    invalid_email: 'E-mail address must be valid',
    password_required: 'Password is required',
    password_length_error: 'Password must be at least 8 characters long',
    password_doesnot_match: 'Confirm password must be identical with the password field',
    streetAddress_required: "Street name is required",
    houseNumber_required: "House number is required",
    addressLocality_required: "City is required",
    postalCode_required: "Postcode is required",
    addressCountry_required: "Country is required",
    company_name_required: "Company name is required",
    line_of_business_required: "Industry is required",
    vat_id_required: "Vat number is required",
  },
  toastMessages: {
    success: 'Success',
    error: "Error",
    copied_to_the_clipboard: 'Copied to the clipboard',
    failed_to_copy: 'Failed to copy'
  },
  order_name:'Order name',
  order_reference_customer_area:'Order Reference',
  translations: 'Translations',
  date: 'Date',
  price: 'Price',
  state: 'Status',
  my_invoices: 'My invoices',
  optional_order_name: 'Optional order name',
  rename_order: 'Rename Order',
  order_number: 'Order Number',
  reason_translation: 'Rejection Translation',
  reason_for_rejection: 'Rejection for rejection',
  stands_reason_of_rejection: 'Here stands the reason of rejection',
  upload_correction_file: "Upload correction file",
  you_hereby_reject_the_translation_and_arrange: 'You hereby reject the translation and arrange for the translation to be corrected. Please let us know where we can improve the translation.',
  choose_from_library: "Choose from library",
  drag_and_drop_files_to_here_to_upload: 'Drag and drop files to here to upload.',
  supports_file_types: 'Supports: JPG, PNG, PDF',
  translated_document: "Translated document",
  new: "New",
  rejected: "Rejected",
  additional: "Additional",
  here_you_will_see_your_digital_translation: "Here you will see your digital translation.",
  uploaded_documents: "Uploaded documents",
  group_chat: "Group chat",
  todo: "To do",
  delivered: "Delivered",
  pending: "Pending",
  chat_is_deactivated_please_contact_our_support_team: 'Chat is deactivated. If there are any questions, please contact our support team.',
  chat_will_be_activated_as_soon_as_a_translator_as_been_matched: 'Chat will be activated shortly. If you have further questions, please contact us via our Messenger, by clicking the message button on the bottom left of this page.',
  there_are_no_messages_yet_we_will_contact_you_if_something_comes_up: 'There are no messages yet. We will contact you if something comes up.',
  matching_translator: 'Matching Translator',
  matching_support: 'Matching Support',
  write_a_message_here: 'Write a message here',
  document_has_been_rejected: 'Document has been rejected',
  reason: 'Reason',
  additional_upload: 'Additional Upload',
  accept: 'Accept',
  accept_translation: 'Accept translation',
  reject_correct: 'Reject & Correct',
  upload_file: 'Upload File',
  reject_translation: 'Reject translation',
  send_corrections: 'Send Corrections',
  you_hereby_accept_the_translation_and_initiate_the_shipment_of_your_documents: 'You hereby accept the translation and initiate the shipment of your documents.',
  shipment_tracking_number: 'Shipment tracking number',
  here_you_will_see_your_shipment_tracking_number: 'Here you will see your shipment tracking number',
  order_information: 'Order Information',
  vat_id: 'Vat Id',
  save_changes: 'Save changes',
  show_canceled_orders: 'Show canceled orders',
  download_invoice: 'Download Invoice',
  payment: 'Payment',
  translations: 'Translations',
  download: 'Download',
  date: 'Date',
  search_order_or_product: 'Search order / product',
  new_translation: 'New Translation',
  my_profile_settings: 'My Profile Settings',
  personal_data: "Personal Data",
  customer_type: "Customer type",
  back_to_configuration: "Back to configuration",
  lingoking_for_businesses: 'lingoking for businesses',
  company_legend: 'lingoking for businesses',
  company: 'Company',
  company_details: 'Company details',
  company_name: 'Company name',
  company_name_required: 'Company name required',
  company_name_too_short: 'Company name is too short',
  company_phone: 'Phone',
  position_in_company: 'Position in the company',
  phone_is_too_short: 'Phone number is too short',
  select_industry: 'Please select an industry',
  small_company_label: "I am a 'Kleinunternehmer' (small business). My income at lingoking is exempt from the sales tax based on section 19 of the German UStG.",
  industry: 'Industry',
  vat_number: 'VAT number',
  vat_required: 'VAT required',
  url_too_short: 'URL is too short',
  timezone: 'Timezone',
  your_timezone: 'Your timezone',
  phone_description: 'Your phone number',
  phone: 'Telephone number',
  mobile_phone: 'Mobile phone number',
  mobile_phone_description: 'Your mobile phone number',
  fax_number: 'Fax number',
  fax_number_description: 'Your fax number',
  settings_notifications: 'Notification Settings',
  subscribe_feedback_email: 'Subscribe Feedback Email',
  newsletter: 'Newsletter',
  your_language: 'Your language',
  business_customer: 'Business customer',
  private_customer: 'Private customer',
  preferred_payment_method: 'Preferred payment method',
  pay_using_stripe: 'Pay using Stripe',
  profile_settings: 'Profile Settings',
  logout: 'Logout',
  get_your_product_name_translated: 'Get your {0} translated',
  go_to_dashboard_upload_later: 'Go to dashboard & upload later',
  please_upload_your_files_now: 'Please upload your files now, so we can find the perfect translator for your project.',
  thank_you: 'Thank you!',
  your_order_has_been_created: 'Your order has been created.',
  password_confirm: 'Confirm Password',
  sign_up: 'Sign up',
  sign_up_now: 'Sign up now',
  sign_in: 'Sign in',
  already_have_an_account: 'Already have an account?',
  do_you_want_to_create_an_account: "Do you want to create an account?",
  i_am_interested_in_receiving: 'I am interested in receiving news or updates regarding the lingoking products and services.',
  terms_of_use_complete_registration: 'By clicking on "complete registration" I agree to the {terms} of lingoking.',
  forgot_your_password: 'Forgot your password?',
  no_account_yet: 'No account yet?',
  your_email_address: 'Your Email Address',
  your_password: 'Your Password',
  reset_password: 'Reset Password',
  submit: 'Submit',
  individual_b2b_solutions: 'Individual B2B solutions',
  upload_files_now: 'Upload files now',
  go_to_dashboard: 'Go to dashboard',
  pending: 'Pending',
  completed: 'Completed',
  dashboard: 'Dashboard',
  help: 'Help',
  invoices: 'Invoices',
  created_at: 'Created at',
  invoice: 'Invoice',
  source_will_also_be_used_target_languages: 'This source will also be used by the following target languages',
  all_translation_products: 'All Translation Services',
  resetFilter: 'Reset filter',
	guidedSearch: {
		tabTitleProduct: "Product",
		tabTitleOccasion: "Occasion",
		productHeadline: "Which translation do you need?",
		occasionHeadline: "What do you need the translation for?",
		occasionBtn: "Show Products",
		prodcutBtnDefault: "Show all Products",
		prodcutBtnSelected: "Go to product",
	},
	orderList: {
		headline: "Your order list",
		summaryheadline: "Summary",
		totalNetAmount: "Total net amount",
		vat: "VAT (19%)",
		totalAmount: "Total amount",
		noProductsInList: "There are currently no products.",
		multiLanguageProducts: "Translations with multiple target languages",
		multiLanguageModalHeadline: "Multiple target languages",
		multiLanguageModalText: "You have configured a translation with several target languages. We treat this configuration as two separate products.",
		singleLanguageProducts: "Translations with one target language",
	},
	productCard: {
		price: "price",
		yourIndividualPrice: "Your individual price",
	},
	productList: {
		filterLabel: 'Filter',
		loadMoreLabel: 'More',
		result: 'Results',
		loading: 'Loading products...',
		searchPlaceholder: 'Search translation',
		selectedOccasion: 'Select your occasion',
		selectedSorting: 'Sort by',
		sortingMostPopular: 'Most popular',
		sortingAbc: 'A-Z',
		resetFilter: 'Reset filter',
		applyBtnLabel: 'Apply',
	},
  orderDetails: {
    source_file_upload_title: "You have to upload the source file!",
    source_file_upload_description: "Please upload your files now, so we can find the perfect translator for your project.",
    translator_title: "Your translation is on progress!",
    translator_description: "Please wait until your translation is finished.",
    corrector_title: "Your translation is being corrected!",
    corrector_description: "Please wait until the correction of your translation is finished.",
    final_revision_title: "Your translation is being reviewed by the translator!",
    final_revision_description: "Please wait until the review of your translation is finished.",
    customer_approval_title: "Your translation is ready!",
    customer_approval_description: "Please evaluate your translation below.",
    shipment_title: "You have no open task!",
    shipment_description: "Your document is being shipped. You can now track your translation via the shipment tracking number.",
    pending_title: "You have no open task!",
    pending_description: "Your order is (now) being processed",
    delivered_title: "Your order is delivered!",
    delivered_description: "You can check the details in the following section.",
  },
  switch_user: 'Switch User',
  got_it: 'Got it',
  please_confirm_that_you_want_to_remove_order: 'Please confirm that you want to remove order',
  remove_from_order_list: 'Remove from order list',
  are_you_sure_remove_your_order_list: 'Are you sure that you want to remove the translation from your order list?',
  order_removed: 'Order removed',
  proceed_to_order: 'Proceed to order',
  find_more_products: 'Find more products',
  all_states: 'All states',
  order_your_translation: 'Order your translation',
  complete_your_order: "Complete your order now",
  password_reset_success: 'If you are a valid user on our platform, you will receive an email to reset your password.',
  reset_successful: 'Reset successful',
  data_successfully_saved: 'Your data has been successfully saved. You will receive e-mails to confirm your account and verify your password.',
  profile_updated: 'Your profile has been updated.',
  impersonate: 'Impersonate',
  pm_account: 'PM account',
  impersonate_mode_is_active: 'Impersonate Mode is Active',
  recaptcha: 'Recaptcha',
  invalid_captcha: 'Invalid captcha',
  request_complete_registration_process: 'To ensure full access to the customer area, we kindly request that you complete the registration process. Click {here} to finish it.',
  request_complete_registration_process_order: 'To access all details of your order, please click {here} to complete your registration process.',
  request_complete_confirmation_process: 'Your email address has not been confirmed. Please check your inbox and confirm it using the link we sent you.',
  here: 'here',
  confirmation_successful: 'Confirmation successful',
  email_confirmation_successfully_sent: 'Email confirmation successfully sent',
  email_confirmation_label: 'Please click here to confirm',
  payment_error_message: 'Payment not successful, please review your payment method.',
  order_comment: 'Order comment',
  add_your_comment: 'Add your comment',

  de: 'German',
  en: 'English',
  es: 'Spanish',
  german: 'German',
  english: 'English',
  spanish: 'Spanish',
  language: 'Language',
  more_languages: 'More languages',
  base_language: 'Favorite languages',
  edit: 'Edit',
  save: 'Save',
  confirm: 'Confirm',
  cancel: 'Cancel',
  delete: 'Delete',
  error: 'Error',
  sorry: 'Sorry!',
  yes: 'Yes',
  no: 'No',
  login: 'Login',
  email: 'Email',
  password: 'Password',
  repeat_password: 'Repeat password',
  forgot_password: 'Forgot your password?',
  error_occurred: 'An error occurred.',
  back: 'Back',
  selectedLabel: 'Selected',
  selectLabel: 'Select',
  deselectLabel: 'Press enter to remove',
  select: 'Choose a',
  recommended: 'RECOMMENDED',
  type_to_search: 'From which language do you wish to translate?',
  no_elements: 'No elements found. Consider changing the search query.',
  empty_list: 'Empty list. Start typing to search.',
  close: 'Close',
  cart_item_remove_cancel: 'Cancel',
  cart_item_remove_confirm: 'Delete',
  remove_from_cart: 'Remove from Cart?',
  remove: 'Remove',
  cart_empty: 'Your cart is empty.',
  link_go_to_products: 'Go to products',
  link_login: 'Login',
  link_contact: 'Contact',
  downloading: 'Downloading',
  open_in_backoffice: 'Open it in backoffice',
  download_quote_pdf: 'Download quote pdf',
  quote_saved_successfully: 'Quote saved successfully',
  quote_sent_successfully: 'The quote was sent successfully.',
  save_as_quote: 'Save as quote',
  send_email_to_customer: 'Send an email to the customer',
  render_checkout_link: 'Render checkout link',

  edit_price: 'Edit price',
  company_name: 'Company name',
  company_metadata: 'Company metadata',

  from: 'from',
  included_vat: 'incl. VAT',
  included_vat_not: 'not incl. VAT',
  total: 'total',
  number_of_pages: 'Number of pages',
  number_of_words: 'Number of words',
  number_of_fixed_items: 'Quantity',
  source_language: 'Source language',
  target_language: 'Target language',
  source_language_placeholder: 'From which language do you wish to translate?',
  target_language_placeholder: 'Into which language do you wish to translate?',
  target_languages: 'Target languages',
  select_language: 'Select a language',
  select_option: 'Select an option',
  translation_option: 'Translation option',
  delivery_option: 'Delivery option',
  additional_options: 'Additional Options',
  required_fields: 'Please, check the required fields below.',
  add_to_cart: 'Add to cart',
  order_now: 'Order now',
  type_of_translation: 'Type of translation',
  document_upload_after_payment: 'Document upload after payment',
  translation_details: 'Translation details',
  info: 'Info',
  refresh_cart: 'Refresh my Cart',
  not_authorized_title: 'Customized Service',
  not_authorized: 'This customized service was created for one of our customers exclusively. Please contact the lingoking team for more information about this service.',
  included: 'Included',
  file_upload: 'File upload',
  file_upload_label: 'Upload a file',
  file_upload_message: 'To ensure the protection of your data, the file upload does not take place until the end of the ordering process.',
  drag_and_drop_label: 'or drag and drop',
  up_to_label: 'up to',
  data_protection_label: 'see data protection',
  data_protection_link: 'https://www.lingoking.com/en/general/privacy-policy',
  terms_of_use_link: 'https://www.lingoking.com/en/general/terms-and-conditions',
  message_to_us: 'Your message to us (optional)',
  message_to_us_description: 'Use the field below if you would like to send us a message, such as something about your order.',
  message_to_us_placeholder: 'Your message',
  order_name: 'Order name',
  order_name_description: 'If you would like to put a name on your order, please place it below.',
  order_name_placeholder: 'Name of your project',
  order_updated: 'Order updated',
  intercom_bot: 'Request advice now',

  pay_safely_with: 'Pay securely with',
  your_shopping_cart: 'Your Shopping Cart',
  item: 'Item',
  items: 'Items',
  estimated_delivery: 'Estimated Delivery',
  digital_delivery_plus_shipping: 'Digital delivery only, shipping additional',
  summary: 'Summary',
  total_amount: 'Total amount',
  total_amount_net: 'Total net amount',
  vat_abbr: 'VAT',
  discount: 'Discount',
  discount_code: 'Discount Code',
  discount_apply: 'Apply',
  discount_apply_label: 'Do you have a discount code?',
  discount_remove: 'Remove',
  discount_message: 'If you have a discount code, enter it below:',
  discount_already_applied: 'Discount already applied! If you want to use a new discount, remove the previous one.',
  discount_already_applied_summary: 'Discount (already applied)',
  discount_not_valid: 'Discount not found or inactive. Please check the code and try again.',
  discount_usage_reached: 'The maximum number of uses for this discount code has been reached.',
  discount_not_applicable: 'This discount code does not apply to any of the products in your shopping cart.',
  discount_min_value: 'To apply this discount you must have a minimum net price of {value} €.',
  continue: 'Continue',
  welcome_back: 'Welcome Back',
  new_here: 'I\'m new here',
  register_now: 'Register now',
  do_you_want_to_create_an_account: 'Do you want to create an account?',
  private: 'Private',
  business: 'Business',
  user_type: 'User Type',
  first_name: 'First name',
  last_name: 'Last name',
  customer_salutation_mrs: 'Mrs',
  customer_salutation_mr: 'Mr',
  your_email: 'Your email address',
  create: 'Create',
  delivery_address: 'Delivery Address',
  delivery_address_desc: 'Check the delivery address below. This will be the delivery address for all orders with physical delivery option selected.',
  billing_address: 'Billing Address',
  billing_address_desc: 'Check the billing address below.',
  same_as_delivery: 'Same as Delivery Address',
  different_billing_address_text: "(Add billing address in next step)",
  gtc_and_privacy: 'GTC und Privacy Policy',
  privacy_policy: 'Privacy Policy',
  imprint: 'Imprint',
  privacy: 'Privacy Policy',
  terms_of_use: 'General Terms and Conditions',
  accept_gtc: 'I hereby confirm that I have read and understood the {0} and the {1}, both of which I accept.',
  accept_gtc_register: 'When you create an account, you agree to our {0}. Please read our {1}.',
  pending_form: 'Please check the form before proceeding.',
  name: 'Name',
  address_street_number: 'Street, number',
  address_street: 'Street',
  number: 'Number',
  address_zip_code: 'ZIP code',
  address_city: 'City',
  address_country: 'Country',
  address_suffix: 'Address Suffix',
  choose_address_country: 'Select a country.',
  choose_address_country_placeholder: 'Type here to search the country',
  payment_method_desc: 'Choose the payment method below',
  pay_upon_invoice: 'Pay Upon Invoice',
  pay_upon_invoice_description: 'Lingoking postpaid payment exclusively for our business customers.',
  order_reference: 'Your Order Reference',
  my_order_reference: 'My order reference',
  pay_upon_optional_field: "Optional, will be shown on your invoice",
  customer_salutation: 'Salutation',
  order_summary: 'Order Summary',
  payment_method: 'Payment Method',
  you_have_discount: 'Do you have a discount code?',
  submit_order: 'Submit order',
  submit_order_info: 'Please upload the source file(s) now. You will then receive an e-mail confirming receipt of the order.',
  checkout: 'Checkout',
  to_checkout: 'To Checkout',
  continue_shopping: 'or Continue Shopping',
  pm_user: 'You are logged in as a PM user. Please select the customer to proceed to checkout.',
  customer_selection_placeholder: 'Customer Email',
  customer_name: 'Customer name',
  customer_email: 'Customer email',
  customer_selected: 'Customer selected',
  select_customer:  'Select Customer',
  change_customer: 'Change Customer',
  search: 'Search',
  set_custom_price: 'Set custom price',
  revert_original_price: 'Revert to original price',
  price: 'Price',
  pm_allowed_edit_price: 'Project managers are allowed to edit order price manually',
  next: 'Next',

  thanks_for_order: 'Thank you for your order!',
  upload_files_after_order: 'Upload files',
  order: 'Order',
  order_created: 'Your order has been created. Please upload the required files.',
  payment_cancelled: 'We are sorry! Please choose another payment method.',
  order_completed_info: 'In your {0} you can view all details of your orders as well as manage other settings.',
  order_created_info: 'You order is created! You will be redirect to checkout. If it does not happen, please click {0}.',
  payment_processed_info: 'You payment is being processed. You will be redirect to the dashboard. If it does not happen, please click {0}.',
  user_area: 'personal account',

  required: 'This field cannot be empty',
  required_min: 'This field should be at least {minLength} long',
  same_as_password: 'The value must be equal to the password',
  valid_email: 'The value is not a valid email address',

  overview_step: 'Overview',
  register_step: 'Register',
  address_step: 'Address',
  payment_method_step: 'Payment',
  confirmation_step: 'Confirmation',
  file_upload_step: 'Upload',

  translation_source_file_already_uploaded: 'Files have already been uploaded.',

  upload_after_payment: 'Upload After Payment',
  another_info_message_click_here: 'Another info message click here',
  add_to_order_list: 'Add to order list',
  configuration: 'Configuration',
  your_individual_price: 'Your individual price (incl. VAT)',

  products_items: '1 Product | {n} Products',

  your_order_has_been_on_hold: 'Your order has been on hold since {0}. Processing will be continued shortly.'
};
