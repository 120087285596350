import {useI18n} from "vue-i18n";

import ProductOptionCategoryType from "../enums/ProductOptionCategoryType";
import PriceUnits from "../enums/PriceUnits";

const useOptions = () => {

  const getAdditionalOptions = (order) => {
    return order.options.filter(option => {
      return option.productOptionCategory === ProductOptionCategoryType.PRODUCT_OPTION_CATEGORY_TYPE_ADDITIONAL
    })
  }

  const getDeliveryOption = (order) => {
    return order.options.find(option => {
      return option.productOptionCategory === ProductOptionCategoryType.PRODUCT_OPTION_CATEGORY_TYPE_DELIVERY
    })
  }

  const getPriceUnitLabel = (product) => {
    const { t } = useI18n()

    if (product.priceUnitLabel) {
      return product.priceUnitLabel
    }

    if (product.priceUnit === PriceUnits.PRICE_UNIT_PAGE) return t('number_of_pages')
    if (product.priceUnit === PriceUnits.PRICE_UNIT_WORD) return t('number_of_words')
    if (product.priceUnit === PriceUnits.PRICE_UNIT_FIXED) return t('number_of_fixed_items')

    return null
  }

  const getTranslationOption = (order) => {
    return order.options.find(option => {
      return option.productOptionCategory === ProductOptionCategoryType.PRODUCT_OPTION_CATEGORY_TYPE_TRANSLATION
    })
  }

  return {
    getAdditionalOptions,
    getDeliveryOption,
    getPriceUnitLabel,
    getTranslationOption
  }
}

export default useOptions;
