export default {
  checkbox: {
    root: {
      class: [
        "relative",
        "inline-flex",
        "align-bottom",
        "w-6",
        "h-6",
        "cursor-pointer",
        "select-none",
        "rounded-md focus-within:ring-1 focus-within:ring-royal-400 outline-none",
        "group"
      ],
    },
    box: ({ props: e, context: r }) => ({
      class: [
        "flex",
        "items-center",
        "justify-center",
        "w-6",
        "h-6",
        "rounded-md",
        "!border-1 border-neutral-700 group-hover:border-royal-400",
        "transition-colors",
        "duration-200",
      ],
    }),
    input: ({ props: e, context: r }) => ({
      class: [
        "peer",
        "w-full ",
        "h-full",
        "absolute",
        "top-0 left-0",
        "z-10",
        "p-0",
        "m-0",
        "opacity-0",
        "rounded-md",
        "outline-none",
        "appareance-none",
        "cursor-pointer"
      ],
    }),
    icon: ({ props: e, context: r }) => ({
      class: [
        "text-base leading-none",
        "w-4",
        "h-4",
        "",
        "transition-all",
        "duration-200",
        [
          r.checked ? 'text-neutral-700 group-hover:text-royal-400' : 'text-white'
        ]
      ],
    }),
  },

  // checkbox1: {
  //   root: {
  //     class: [
  //       "cursor-pointer inline-flex relative select-none align-bottom",
  //       "w-6 h-6",
  //     ],
  //   },
  //   input: ({ props, context, state }) => ({
  //     class: [
  //       "peer",
  //       "w-full ",
  //       "h-full",
  //       "absolute",
  //       "top-0 left-0",
  //       "z-10",
  //       "p-0",
  //       "m-0",
  //       "opacity-0",
  //       "rounded-md",
  //       "outline-none",
  //       "border-2 border-neutral-600",
  //       "appareance-none",
  //       "cursor-pointer",
  //       "group",
  //       {
  //         "border-royal-300 ring-1 ring-royal-300":
  //           state.focused && !props.disabled,
  //         "bg-white": !context.checked,
  //         "text-neutral-700 bg-white": context.checked,
  //         "hover:border-royal-300": !props.disabled,
  //         "cursor-default opacity-60": props.disabled,
  //       },
  //     ],
  //   }),
  //   box: ({ props: e, context: r }) => ({
  //     class: [
  //       "flex",
  //       "items-center",
  //       "justify-center",
  //       "w-6",
  //       "h-6",
  //       "rounded-md",
  //       "border-1 border-neutral-600",
  //       {
  //         "border-surface-200 bg-surface-0": !r.checked,
  //         "border-neutral-700": r.checked,
  //       },
  //       {
  //         "peer-hover:border-primary-500": !e.disabled && !r.checked,
  //         "peer-hover:bg-primary-600 peer-hover:border-primary-700":
  //           !e.disabled && r.checked,
  //         "peer-focus-visible:border-primary-500 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20":
  //           !e.disabled,
  //         "cursor-default opacity-60": e.disabled,
  //       },
  //       "transition-colors",
  //       "duration-200",
  //     ],
  //   }),
  //   icon: ({ context }) => [
  //     "w-4 h-4 transition-all duration-200 text-base",
  //     {
  //       "text-white": !context.checked,
  //       "text-neutral-700 group-hover:text-royal-300": context.checked,
  //     },
  //   ],
  // },
};
