<template>
  <TabView>
    <TabPanel :header="$t('configuration')">
      <div class="flex flex-col space-y-3">
        <p v-if="v$.$error" class="font-extralight text-sm text-red-600 text-center ml-1">
          {{ $t('required_fields') }}
        </p>
        <!-- Pages/Words -->
        <div class="my-1">
          <div class="flex flex-col gap-2 h-20">
            <label for="pagesOrWords">{{ order.product ? getPriceUnitLabel(order.product) : $t('number_of_fixed_items')}}</label>
            <Skeleton class="!h-full" v-if="!order.product" />
            <div v-else>
                <span :class="{ 'p-input-icon-left': order.product.howToTips && order.product.howToTips.length }">
                <span v-if="order.product.howToTips && order.product.howToTips.length" class="iconlk-info cursor-pointer text-[24px]" @click="productHowToTipModal = true"></span>
                <InputNumber
                  v-model="order.pagesOrWords"
                  inputId="pagesOrWords"
                  mode="decimal"
                  showButtons
                  :min="1"/>
              </span>
            </div>
          </div>
        </div>
        <!-- Source Language -->
        <div class="my-1">
          <div class="flex flex-col gap-1 h-20">
            <label>{{ $t('source_language') }}</label>
            <Skeleton class="!h-full" v-if="!order.product" />
            <div v-else>
              <Language
                v-model="order.sourceLanguage"
                :order="order"
                :product="order.product"
                :languages-options="order.product.sourceLanguages"
                :label="$t('source_language')"
                :placeholder="$t('source_language_placeholder')" />
            </div>
          </div>
        </div>
        <!-- Target Language -->
        <div class="my-1">
          <div class="flex flex-col gap-1 h-20">
            <label>{{ $t('target_language') }}</label>
            <Skeleton class="!h-full" v-if="!order.product" />
            <div v-else>
              <Language
                v-model="order.targetLanguages"
                :id="'targetLanguages'"
                :order="order"
                :product="order.product"
                :languages-options="order.product.targetLanguages"
                :label="$t('target_language')"
                :placeholder="$t('target_language_placeholder')"
                :multiple="true" />
            </div>
          </div>
        </div>
        <!-- Translation Option -->
        <div class="my-1">
          <div class="flex flex-col gap-1 h-20">
            <label>{{ $t('translation_option') }}</label>
            <Skeleton class="!h-full" v-if="!order.product" />
            <div v-else>
              <ProductOption
                v-model="order.selectedTranslationOption"
                :options="order.product.productOptions"
                :error="v$.selectedTranslationOption.$error"
                :name="$t('translation_option')"
                :label="$t('translation_option')"
                :get-option-price="getTranslationOptionPrice"
                :calculated-order="calculatedOrder"
                :category-type="productOptionCategoryType.PRODUCT_OPTION_CATEGORY_TYPE_TRANSLATION"
                :depending-on="false"
              />
            </div>
          </div>
        </div>
        <!-- Delivery Option -->
        <div class="my-1">
          <div class="flex flex-col gap-1 h-20">
            <label>{{ $t('delivery_option') }}</label>
            <Skeleton class="!h-full" v-if="!order.product" />
            <div v-else>
              <ProductOption
                v-model="order.selectedDeliveryOption"
                :options="order.product.productOptions"
                :error="v$.selectedDeliveryOption.$error"
                :name="$t('delivery_option')"
                :label="$t('delivery_option')"
                :get-option-price="getDeliveryOptionPrice"
                :calculated-order="calculatedOrder"
                :category-type="productOptionCategoryType.PRODUCT_OPTION_CATEGORY_TYPE_DELIVERY"
                :depending-on="true"
                :dependency-option="order.selectedTranslationOption"
              />
            </div>
          </div>
        </div>
        <!-- Additional Options (Highlighted)  -->
        <AdditionalOptions :additional-options="highlightedAdditionalOptions" />
      </div>

      <!-- Checkout -->
      <div class="flex flex-col justify-center gap-2.5 mt-6">
        <Button
          ref="addToCartButton"
          iconPos="right"
          icon="iconlk-arrow-next"
          severity="secondary"
          rounded
          :label="primaryButtonLabel"
          :disabled="isLoading || (createdOrder && !editMode)"
          @click="addToCartButtonHandler"
        />
        <!-- <button v-if="intercomBot1" id="intercom-chat-bot-1" type="button" class="transition rounded-md inline-flex items-center px-4 py-0.5 justify-center border-2 border-yellow-400 text-base font-medium shadow-sm text-yellow-400 hover:bg-yellow-600 hover:border-yellow-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
          </svg>
          <span class="ml-1">{{ intercomBotButtonName1 }}</span>
        </button>
        <button v-if="intercomBot2" id="intercom-chat-bot-2" type="button" class="transition rounded-md inline-flex items-center px-4 py-0.5 justify-center border-2 border-yellow-400 text-base font-medium shadow-sm text-yellow-400 hover:bg-yellow-600 hover:border-yellow-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-600">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
          </svg>
          <span class="ml-1">{{ intercomBotButtonName2 }}</span>
        </button> -->
      </div>
      <div class="flex flex-col justify-center gap-2.5 mt-2">
        <Button
          ref="addToOrderListButton"
          :label="$t('add_to_order_list')"
          :disabled="isLoading"
          class="text-royal-400"
          rounded
          @click="addToOrderListButtonHandler"
        />
      </div>
      <!-- <a href="/order-list" class="block text-royal-400 m-4 text-center">{{ $t('add_to_order_list') }}</a> -->
    </TabPanel>
    <TabPanel :header="$t('additional_options')">
      <div class="grid gap-y-5">
        <AdditionalOptions :additional-options="otherAdditionalOptions" />
        <!-- Default Order Information -->
        <div class="flex flex-col gap-1 h-20">
            <label for="orderName">{{ $t('order_name') }} </label>
          <Skeleton v-if="!order.product" class="!h-full" />
          <InputText v-else id="orderName" v-model="order.orderName" aria-describedby="order-name" :placeholder="$t('order_name_placeholder')" />
        </div>
        <div class="flex flex-col gap-1">
            <label for="orderMessage">{{ $t('message_to_us') }} </label>
            <Skeleton v-if="!order.product" class="!h-full" />
            <Textarea v-else v-model="order.message"  id="orderMessage" aria-describedby="order-message" autoResize rows="1" :placeholder="$t('message_to_us_placeholder')" />
        </div>
      </div>
    </TabPanel>
  </TabView>

  <ConfirmDialog>
    <template #message="slotProps">
      <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
        <div v-html="slotProps.message.message" />
      </div>
    </template>
  </ConfirmDialog>

  <!-- Product How to Tip -->
  <Dialog v-model:visible="productHowToTipModal" modal>
    <template #header>
      <div class="inline-flex items-center justify-center gap-2">
        <span class="iconlk-info text-[24px]"></span>
        <span class="font-bold white-space-nowrap text-[26px]">{{ order.product.howToTips[0].headline }}</span>
    </div>
    </template>
    <div class="m-0 text-sm" v-html="order.product.howToTips[0].text" />
    <template #footer>
      <Button
        severity="standard"
        label="Got it"
        @click="productHowToTipModal = false"
        rounded
      />
    </template>
  </Dialog>
</template>

<script>
/* eslint-disable */

import {computed, inject, onMounted, provide, ref, toRef, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useVuelidate} from '@vuelidate/core';

// Composables
import {getScreenManager} from '@/composables/screenManager';
import {debounce} from '@/composables/debounce';
import {useIntercomTrackOrder} from '@/composables/intercomTrackOrder'
import useOptions from '@/composables/useOptions';
import useOrder from '@/composables/useOrder';
import useOrderPrice from '@/composables/useOrderPrice';

// Components
import AdditionalOptions from '../options/Additional.vue';
import Language from '../options/Language.vue';
import ProductOption from '../options/ProductOption.vue';

import ProductOptionCategoryType from '../../enums/ProductOptionCategoryType';
import PriceUnits from '../../enums/PriceUnits';

import { Posthog } from '@lingoking-node-apps/analytics';

import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Textarea  from 'primevue/textarea';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Skeleton from 'primevue/skeleton'

export default {
  name: 'Form',
  components: {
    AdditionalOptions,
    Button,
    Language,
    ProductOption,
    ConfirmDialog,
    Dialog,
    InputNumber,
    InputText,
    TabPanel,
    TabView,
    Textarea,
    ProgressSpinner,
    Skeleton
  },
  props: ['development', 'productForOrder', 'initialOrder', 'primaryButtonLabel', 'onSubmit', 'editMode'],
  setup(props) {
    const store = useStore()
    const { n, t, locale } = useI18n()
    const { rules } = useOrder()
    const { getPriceUnitLabel } = useOptions()
    const { calculateOrderPrice } = useOrderPrice()

    // Order
    const order = toRef(props, 'initialOrder')

    const howToTipModal = ref()
    const productHowToTipModal = ref(false)
    const addToCartButton = ref(null)
    const addToOrderListButton = ref(null)

    const intercomBot1 = inject('intercomBot1')
    const intercomBot2 = inject('intercomBot2')

    const intercomBotButtonName1 = inject('intercomChatBotButtonName1')
    const intercomBotButtonName2 = inject('intercomChatBotButtonName2')

    // Button label
    const primaryButtonLabel = computed(() => {
      if (props.primaryButtonLabel) {
        return props.primaryButtonLabel
      }

      return props.editMode ? t('refresh_cart') : t('next')
    })

    // Additional Options
    const additionalOptionsFiltered = computed(() => {
      if (!order.value.product) {
        return []
      }

      return (order.value.additionalOptions).filter(additionalOption => {
        if (additionalOption.productOptionCombination.productOption.dependingOn.length > 0) {
          let includedTranslationOption = order.value.selectedTranslationOption ? additionalOption.productOptionCombination.productOption.dependingOn.includes(order.value.selectedTranslationOption.productOption.name) : true
          let includedDeliveryOption = order.value.selectedDeliveryOption ? additionalOption.productOptionCombination.productOption.dependingOn.includes(order.value.selectedDeliveryOption.productOption.name) : true

          return includedTranslationOption || includedDeliveryOption
        }

        return true;
      }).sort((additionalOption, nextAdditionalOption) => additionalOption.productOptionCombination.productOption.label < nextAdditionalOption.productOptionCombination.productOption.label ? -1 : 1)
    })
    const highlightedAdditionalOptions = computed(() => {
      return additionalOptionsFiltered.value.filter(additionalOption => {
        return additionalOption.productOptionCombination.highlighted;
      })
    })
    const otherAdditionalOptions = computed(() => {
      return additionalOptionsFiltered.value.filter(additionalOption => {
        return !additionalOption.productOptionCombination.highlighted;
      })
    })

    // Order Process
    const createdOrder = computed(() => { return store.getters["order/order"] })
    const calculatedOrder = computed(() => { return store.getters["order/calculatedOrder"] })

    const isLoading = computed(() => { return store.state.isLoading })
    const isLoadingOrder = computed(() => { return store.getters["order/isLoading"] })

    const productOptionCategoryType = ProductOptionCategoryType
    const priceUnits = PriceUnits

    const v$ = useVuelidate(rules, order)

    onMounted(() => {
      useIntercomTrackOrder(order.value)
    })

    watch(() => [
      order.value.pagesOrWords,
      order.value.sourceLanguage,
      order.value.targetLanguages,
      order.value.selectedTranslationOption,
      order.value.selectedDeliveryOption], () => {
      updateOrderPriceDebounced()
    })

    watch(() => order.value.additionalOptions, () => {
      updateOrderPriceDebounced()
    }, { deep: true })

    watch(calculatedOrder, (calculatedOrder) => {
      Posthog.capture.captureUserInteraction({ order: calculatedOrder })
    })

    // Functions
    const updateOrderPriceDebounced = debounce(function () { calculateOrderPrice(order.value) }, 500)

    const { isMobile } = getScreenManager()

    const addToCartButtonHandler = () => {
      v$.value.$touch()
      if (v$.value.$error) return
      addToCartButton.value.disabled = true
      addToOrderListButton.value.disabled = true

      props.onSubmit(order.value, 'checkout')
    }

    const addToOrderListButtonHandler = () => {
      v$.value.$touch()
      if (v$.value.$error) return
      addToCartButton.value.disabled = true
      addToOrderListButton.value.disabled = true

      props.onSubmit(order.value, 'cart')
    }

    const getTranslationOptionPrice = (optionCombination) => {
      if(calculatedOrder.value.options) {
        let calculatedOption = calculatedOrder.value.options['hydra:member'].find(option => {
          return option.productOption === optionCombination.productOption.id
        })

        return calculatedOption.productOptionPrice === 0
          ? ''
          : n(calculatedOption.productOptionPrice, 'currency', locale.value)
      }
    }

    const getDeliveryOptionPrice = (optionCombination) => {
      if(calculatedOrder.value.options) {
        let calculatedOption = calculatedOrder.value.options['hydra:member'].find(option => {
          return option.productOption === optionCombination.productOption.id
        })

        let calculatedTranslationOption = calculatedOrder.value.options['hydra:member'].find(option => {
          return option.productOption === order.value.selectedTranslationOption.productOption.id
        })

        let optionPrice = calculatedTranslationOption.productOptionPrice - calculatedOption.productOptionPrice

        return optionPrice === 0 ? t('included') : n(Math.abs(optionPrice), 'currency', locale.value)
      }
    }

    const scrollAdditionalOptions = (open) => {
      if(!isMobile.value) {
        return
      }

      open ? document.getElementById("prices").scrollIntoView({ behavior: 'smooth', block: 'start'}) : document.getElementById("additionalOptions").scrollIntoView({ behavior: 'smooth', block: 'start'})
    }

    // Providers
    provide('getOptionPrice', getTranslationOptionPrice)

    return {
      primaryButtonLabel,
      addToCartButton,
      addToOrderListButton,
      howToTipModal,
      productHowToTipModal,
      intercomBot1,
      intercomBot2,
      intercomBotButtonName1,
      intercomBotButtonName2,
      isLoading,
      isLoadingOrder,
      isMobile,
      highlightedAdditionalOptions,
      otherAdditionalOptions,
      calculatedOrder,
      order,
      createdOrder,
      priceUnits,
      productOptionCategoryType,
      addToCartButtonHandler,
      addToOrderListButtonHandler,
      getDeliveryOptionPrice,
      getPriceUnitLabel,
      getTranslationOptionPrice,
      scrollAdditionalOptions,
      locale,
      rules,
      v$
    }
  }
}
</script>

<style scoped>

</style>
