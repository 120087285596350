<template>
  <component
    v-for="(additionalOption, index) in additionalOptions"
    :additional-option="additionalOption"
    :is="getOptionFormType(additionalOption.productOptionCombination).componentType"
    :key="index"
    :key-val="index"
    :label="additionalOption.productOptionCombination.productOption.label"
    :option-form-type="getOptionFormType(additionalOption.productOptionCombination)"
  />
</template>

<script>
import ProductOptionFormType from "../../enums/ProductOptionFormType";

import Input from "@/components/options/types/Input.vue";
import MultiSelect from "@/components/options/types/MultiSelect.vue";

export default {
  name: "AdditionalOptions",
  props: ["additionalOptions", "labelClasses"],
  components: {
    Input,
    MultiSelect,
  },
  setup() {
    const getOptionFormType = (optionCombination) => {
      let typeOptions = Object.keys(ProductOptionFormType).map(function (key) {
        return ProductOptionFormType[key];
      });

      return typeOptions.find((option) => {
        return option.type === optionCombination.productOption.type;
      });
    };

    return {
      getOptionFormType,
    };
  },
};
</script>

<style scoped></style>
