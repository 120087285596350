
export default {
    textarea: {
        root: ({ context }) => ({
            class: [
                'm-0 p-3',
                'font-sans text-base text-neutral-800 bg-white transition-colors duration-200 appearance-none rounded-lg',
                'focus:ring-1 focus:ring-royal-400 outline-none',
                '!border border-neutral-600 hover:border-royal-300',
                { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled }
            ]
        })
    }
}
