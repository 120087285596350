<template>
  <div v-if="multiple">
    <MultiSelect
      ref="multiSelect"
      filter
      dataKey="id"
      v-model="language"
      :options="options"
      :placeholder="$t('select_language')"
      optionLabel="name"
      optionGroupLabel="priority"
      optionGroupChildren="languages"
      :showToggleAll=false
      display="chip"
      class="w-full md:w-20rem"
      @change="handleChange"
    >
      <template #optiongroup="slotProps">
        <div class="flex align-items-center">
          <div>{{ slotProps.option.priority }}</div>
        </div>
      </template>
    </MultiSelect>
  </div>
  <div v-else>
    <Dropdown
      filter
      dataKey="id"
      v-model="language"
      :options="options"
      :placeholder="$t('select_language')"
      optionLabel="name"
      optionGroupLabel="priority"
      optionGroupChildren="languages"
      class="w-full md:w-14rem"
    >
      <template #optiongroup="slotProps">
        <div class="flex align-items-center">
          <div>{{ slotProps.option.priority }}</div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'

import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'

const props = defineProps([
  'modelValue',
  'id',
  'order',
  'product',
  'labelClasses',
  'languagesOptions',
  'rules',
  'label',
  'placeholder',
  'name',
  'multiple'
])

const {t} = useI18n()

// model
const language = defineModel()

// refs
const options = ref(null)
const multiSelect = ref(null)

const handleChange = () => {
  if (multiSelect.value) {
    multiSelect.value.hide()
  }
}

const addLanguage = (order) => {
  order.targetLanguages.push(null)
}

const removeLanguage = (order) => {
  order.targetLanguages.pop()
}

const v$ = useVuelidate(Object.assign({required}, props.rules), language)

function getOptionsFiltered() {
  let languages = []

  if (props.product['@id']) {
    languages.push({
      priority: t('base_language'),
      languages: filterLanguages(props.languagesOptions, true)
    })
    languages.push({
      priority: t('more_languages'),
      languages: filterLanguages(props.languagesOptions, false)
    })
  }

  return languages
}

function filterLanguages(languages, isBase) {
  return languages.filter((language) => {
    return language.baseLanguage === isBase
  })
}

// hooks
onMounted(() => {
  options.value = getOptionsFiltered()
})
</script>

<style scoped></style>
