import { onMounted, watch, ref } from 'vue'

/**
 * For each moditication in the order we need send a track event to Intercom.
 * It will help the support team to have more information about client's order.
 */
export const useIntercomTrackOrder = (order) => {
  const fn = ref(() => { })

  const convertOrderToEventMetadata = () => {
    let data = {
      "order_name": order.orderName || "",
      "pages_or_words": order.pagesOrWords || "",
      "message": order.message || "",
      "product_id": order.product.id,
      "product_name": order.product.name,
      "source_language": order.sourceLanguage ? order.sourceLanguage.name : "",
      "target_languages": order.targetLanguages instanceof Array ? order.targetLanguages.filter(t => !!t).map(t => t.name).join(",") : "",
    }
    if (order.selectedDeliveryOption) {
      data["selected_delivery_option"] = order.selectedDeliveryOption.productOption.name
    }
    if (order.selectedTranslationOption) {
      data["selected_translation_option"] = order.selectedTranslationOption.productOption.name
    }
    if (order.additionalOptions) {
      order.additionalOptions.map(option => {
        let key = "option_" + option.productOptionCombination.productOption.name.replace(" ", "_")
        data[key] = option.productOptionValue || "null"
      })
    }
    return data
  }

  const sendTrackEvent = () => {
    if (!window.Intercom) {
      console.warn('window.Intercom not found')
      return
    }
    clearTimeout(fn.value)
    const metadata = convertOrderToEventMetadata()
    fn.value = setTimeout(() => window.Intercom('trackEvent', 'product-configurator', metadata), 1000)
  }

  onMounted(order)
  watch(order, sendTrackEvent)
}
