export default {
  translations_for_every_need: "Übersetzungen für jeden Bedarf.",
  login_page_subtitle: "Herzlich Willkommen bei lingoking - Ihrer Plattform für Übersetzungen aller Art! Melden Sie sich jetzt an, um Ihre Übersetzungsaufträge fortzusetzen oder neue zu starten.",
  greetings: "Hallo",
  home_page_subtitle: "Herzlich Willkommen zurück bei lingoking. In Ihrem Dashboard finden Sie eine Übersicht über all Ihre Aufträge und offenen Aufgaben.",
  my_order_page_subtitle: "Hier finden Sie eine Übersicht über all Ihre Bestellungen, sowie deren Status.",
  my_invoice_page_subtitle: "Hier finden Sie eine Übersicht über all Ihre Rechnungen. Diese stehen Ihnen nach Abschluss der Lieferung zum Download zur Verfügung.",
  new_translation_subtitle: 'Suchen Sie jetzt ganz einfach eine für Sie passende Übersetzung und starten den Bestellprozess.',
  see_all: "Alle anzeigen",
  translations_in_progress: "Übersetzungen in Bearbeitung",
  completed_translations_in: "Abgeschlossene Übersetzungen in {currentYear}",
  your_tasks: "Ihre Aufgaben",
  order_again: "Erneut bestellen",
  recommendations: "Empfehlungen",
  chats: "Chats",
  page_not_found: "Seite nicht gefunden",
  page_not_found_description: "Die Seite, die Sie suchen, existiert nicht. Hier sind einige hilfreiche Links",
  no_open_tasks_found: "Sie haben keine offenen Aufgaben – Sie können sich zurücklehnen!",
  products_not_found: "Produkte nicht gefunden",
  its_now_our_turn_asap: "Jetzt sind wir an der Reihe, sicherzustellen, dass Sie Ihre Übersetzung so schnell wie möglich erhalten.",
  you_have_no_chats_yet: "Sie haben noch keine Chats.",
  we_our_contact_open_questions: "Wir oder unsere Übersetzer werden Sie kontaktieren, falls Fragen offen sind.",
  your_custom_translation_products: "Ihre individuellen Übersetzungsprodukte",
  your_company_custom_translation_products: "Individuelle Produkte Ihrer Firma",
  custom: "Kunde",
  my_orders: "Meine Bestellungen",
  cartStatuses: {
    processing: "Bearbeitung",
    open: "Offen",
    quote: "Angebot",
    finished: "Abgeschlossen",
    cancelled: 'Abgebrochen',
  },
  orderStatuses: {
    draft: 'Entwurf',
    setup: 'Einrichtung',
    processing: 'Bearbeitung',
    delivered: 'Geliefert',
    cancelled: 'Abgebrochen',
    deleted: 'Gelöscht'
  },
  orderSortOptions: {
    newest_activity: "Neueste Aktivität",
    newest_order_date: "Neuestes Bestelldatum",
    oldest_order_date: "Ältestes Bestelldatum",
  },
  orderStatusOptions: {
    draft: "Entwurf",
    setup: "Einrichtung",
    processing: "Bearbeitung",
    delivered: "Geliefert",
    canceled: "Abgebrochen",
    deleted: "Gelöscht",
  },
  invoiceSortOptions: {
    date: 'Datum',
    payment_state: 'Zahlungsstatus',
  },
  invoiceStatuses: {
    draft: 'Entwurf',
    open: 'Offen',
    paid: 'Bezahlt',
    void: 'Ungültig',
    uncollectible: 'Uneinbringlich',
    deleted: 'Gelöscht',
    settled: 'Bezahlt'
  },
  jobstatuses: {
    draft: 'Entwurf',
    recruiting: 'Rekrutierung',
    queued: 'In Warteschlange',
    realization: 'Umsetzung',
    finished: 'Abgeschlossen',
    collected: 'Eingezogen',
    paid: 'Bezahlt',
    cancelled: 'Storniert',
    deleted: 'Gelöscht'
  },
  jobDefinitionTypes: {
    translator: 'Übersetzer',
    corrector: 'Korrektor',
    final_revision: 'Endüberprüfung',
    qm: 'QM',
    final_revision_qm: 'Endüberprüfung QM',
    customer_approval: 'Kundenfreigabe',
    order_adjustment: 'Auftragsanpassung',
    printing: 'Druck',
    shipment: 'Versand',
    confirmation: 'Bestätigung',
    xrechnung_validation: 'XRechnung-Validierung',
    file_upload: 'Datei-Upload'
  },
  validationErrorMessages: {
    user_type_required: 'Benutzertyp ist ein Pflichtfeld',
    first_name_required: 'Vorname ist erforderlich',
    last_name_required: 'Nachname ist erforderlich',
    email_required: 'E-Mail ist erforderlich',
    timezone_required: 'Zeitzone ist erforderlich',
    invalid_email: 'E-Mail-Adresse muss gültig sein',
    password_required: 'Passwort ist erforderlich',
    password_length_error: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    password_doesnot_match: 'Das Bestätigungspasswort muss mit dem Passwortfeld identisch sein.',
    streetAddress_required: "Straße ist erforderlich",
    houseNumber_required: "Hausnummer ist erforderlich",
    addressLocality_required: "Stadt ist erforderlich",
    postalCode_required: "Postleitzahl ist erforderlich",
    addressCountry_required: "Land ist erforderlich",
    company_name_required: "Unternehmen ist erforderlich",
    line_of_business_required: "Branche ist erforderlich",
    vat_id_required: "USt-IdNr. ist erforderlich",
  },
  toastMessages: {
    success: 'Erfolg',
    error: "Fehler",
    copied_to_the_clipboard: 'In die Zwischenablage kopiert',
    failed_to_copy: 'Kopieren fehlgeschlagen'
  },
  order_name:'Bestellname',
  order_reference_customer_area:'Auftragsreferenz',
  translations: 'Übersetzungen',
  date: 'Datum',
  price: 'Preis',
  state: 'Status',
  my_invoices: "Meine Rechnungen",
  optional_order_name: "Optionaler Bestellname",
  rename_order: "Bestellung umbenennen",
  order_number: "Bestellnummer",
  reason_translation: "Ablehnungsübersetzung",
  reason_for_rejection: "Grund für die Ablehnung",
  stands_reason_of_rejection: "Hier steht der Ablehnungsgrund",
  upload_correction_file: "Korrekturdatei hochladen",
  you_hereby_reject_the_translation_and_arrange: "Sie lehnen hiermit die Übersetzung ab und veranlassen, dass die Übersetzung korrigiert wird. Bitte teilen Sie uns mit, wo wir die Übersetzung verbessern können.",
  drag_and_drop_files_to_here_to_upload: "Ziehen Sie Dateien hierher, um sie hochzuladen.",
  choose_from_library: "Aus der Bibliothek wählen",
  supports_file_types: "Unterstützt: JPG, PNG, PDF",
  translated_document: "Übersetztes Dokument",
  new: "Neu",
  rejected: "Abgelehnt",
  additional: "Zusätzlich",
  here_you_will_see_your_digital_translation: "Hier sehen Sie Ihre digitale Übersetzung.",
  uploaded_documents: "Hochgeladene Dokumente",
  group_chat: "Gruppenchat",
  todo: "Zu tun",
  delivered: "Geliefert",
  pending: "Ausstehend",
  chat_is_deactivated_please_contact_our_support_team: "Der Chat ist deaktiviert. Bei Fragen wenden Sie sich bitte an unser Support-Team.",
  chat_will_be_activated_as_soon_as_a_translator_as_been_matched: "Der Chat wird in Kürze aktiviert. Wenn Sie weitere Fragen haben, kontaktieren Sie uns bitte über unseren Messenger, indem Sie auf die Nachrichtenschaltfläche unten links auf dieser Seite klicken.",
  there_are_no_messages_yet_we_will_contact_you_if_something_comes_up: "Es gibt noch keine Nachrichten. Wir werden Sie kontaktieren, wenn etwas ansteht.",
  matching_translator: "Passender Übersetzer",
  matching_support: "Passender Support",
  write_a_message_here: "Schreiben Sie hier eine Nachricht",
  document_has_been_rejected: "Dokument wurde abgelehnt",
  reason: "Grund",
  additional_upload: "Zusätzlicher Upload",
  accept: "Akzeptieren",
  accept_translation: "Übersetzung akzeptieren",
  reject_correct: "Ablehnen & Korrigieren",
  upload_file: "Datei hochladen",
  reject_translation: "Übersetzung ablehnen",
  send_corrections: "Korrekturen senden",
  you_hereby_accept_the_translation_and_initiate_the_shipment_of_your_documents: "Hiermit akzeptieren Sie die Übersetzung und leiten den Versand Ihrer Dokumente ein.",
  shipment_tracking_number: "Sendungsverfolgungsnummer",
  here_you_will_see_your_shipment_tracking_number: "Hier sehen Sie Ihre Sendungsverfolgungsnummer.",
  order_information: "Bestellinformationen",
  vat_id: "USt-IdNr.",
  save_changes: "Änderungen speichern",
  show_canceled_orders: "Stornierte Bestellungen anzeigen",
  download_invoice: "Rechnung herunterladen",
  payment: "Zahlung",
  translations: "Übersetzungen",
  download: "Herunterladen",
  date: "Datum",
  search_order_or_product: "Bestellung / Produkt suchen",
  new_translation: "Neue Übersetzung",
  my_profile_settings: "Meine Profil-Einstellungen",
  personal_data: "Persönliche Daten",
  customer_type: "Kundentyp",
  back_to_configuration: "Zurück zur Konfiguration",
  lingoking_for_businesses: "lingoking für Unternehmen",
  company_legend: "lingoking für Unternehmen",
  company: "Unternehmen",
  company_details: "Unternehmensdetails",
  company_name: "Firmenname",
  company_name_required: "Firmenname erforderlich",
  company_name_too_short: "Firmenname ist zu kurz",
  company_phone: "Telefon",
  position_in_company: "Position im Unternehmen",
  phone_is_too_short: "Telefonnummer ist zu kurz",
  select_industry: "Bitte wählen Sie eine Branche",
  small_company_label: "Ich bin ein Kleinunternehmer. Mein Einkommen bei lingoking ist gemäß § 19 des deutschen UStG von der Umsatzsteuer befreit.",
  industry: "Branche",
  vat_number: "USt-IdNr.",
  vat_required: "USt erforderlich",
  url_too_short: "URL ist zu kurz",
  timezone: "Zeitzone",
  your_timezone: "Ihre Zeitzone",
  phone_description: "Ihre Telefonnummer",
  phone: "Telefonnummer",
  mobile_phone: "Handynummer",
  mobile_phone_description: "Ihre Handynummer",
  fax_number: "Faxnummer",
  fax_number_description: "Ihre Faxnummer",
  settings_notifications: "Benachrichtigungseinstellungen",
  subscribe_feedback_email: "Feedback-E-Mail abonnieren",
  newsletter: "Newsletter",
  your_language: "Ihre Sprache",
  business_customer: "Geschäftskunde",
  private_customer: "Privatkunde",
  preferred_payment_method: "Bevorzugte Zahlungsmethode",
  pay_using_stripe: "Mit Stripe bezahlen",
  profile_settings: "Profil-Einstellungen",
  logout: "Abmelden",
  get_your_product_name_translated: "Lassen Sie Ihren {0} übersetzen",
  go_to_dashboard_upload_later: "Zum Dashboard gehen & später hochladen",
  please_upload_your_files_now: "Bitte laden Sie jetzt Ihre Dateien hoch, damit wir den perfekten Übersetzer für Ihr Projekt finden können.",
  thank_you: "Danke!",
  your_order_has_been_created: "Ihre Bestellung wurde erstellt.",
  password_confirm: "Passwort bestätigen",
  sign_up: "Registrieren",
  sign_up_now: "Jetzt registrieren",
  sign_in: "Anmelden",
  already_have_an_account: "Haben Sie bereits ein Konto?",
  do_you_want_to_create_an_account: "Sie haben bereits ein Konto?",
  i_am_interested_in_receiving: "Ich bin daran interessiert, Neuigkeiten oder Updates zu den Produkten und Dienstleistungen von lingoking zu erhalten.",
  terms_of_use_complete_registration: "Durch Klicken auf \"Registrierung abschließen\" akzeptiere ich die {terms} von lingoking.",
  forgot_your_password: "Passwort vergessen?",
  no_account_yet: "Noch kein Konto?",
  your_email_address: "Ihre E-Mail-Adresse",
  your_password: "Ihr Passwort",
  reset_password: "Passwort zurücksetzen",
  submit: "Absenden",
  individual_b2b_solutions: "Individuelle B2B-Lösungen",
  upload_files_now: "Dateien jetzt hochladen",
  go_to_dashboard: "Zum Dashboard gehen",
  pending: "Ausstehend",
  completed: "Abgeschlossen",
  dashboard: "Dashboard",
  help: "Hilfe",
  invoices: "Rechnungen",
  created_at: "Erstellt am",
  invoice: "Rechnung",
  source_will_also_be_used_target_languages: "Diese Quelle wird auch von den folgenden Zielsprachen verwendet",
  all_translation_products: "Alle Übersetzungsservices",
  resetFilter: "Filter zurücksetzen",
  guidedSearch: {
    tabTitleProduct: "Produkt",
    tabTitleOccasion: "Anlass",
    productHeadline: "Welche Übersetzung benötigen Sie?",
    occasionHeadline: "Wofür benötigen Sie die Übersetzung?",
    occasionBtn: "Produkte anzeigen",
    prodcutBtnDefault: "Alle Produkte anzeigen",
    prodcutBtnSelected: "Zum Produkt gehen"
  },
  orderList: {
    headline: "Ihre Bestellliste",
    summaryheadline: "Zusammenfassung",
    totalNetAmount: "Gesamtbetrag netto",
    vat: "MwSt (19%)",
    totalAmount: "Gesamtbetrag",
    noProductsInList: "Es gibt derzeit keine Produkte.",
    multiLanguageProducts: "Übersetzungen mit mehreren Zielsprachen",
    multiLanguageModalHeadline: "Mehrere Zielsprachen",
    multiLanguageModalText: "Sie haben eine Übersetzung mit mehreren Zielsprachen konfiguriert. Wir behandeln diese Konfiguration als zwei separate Produkte.",
    singleLanguageProducts: "Übersetzungen mit einer Zielsprache"
  },
  productCard: {
    price: "Preis",
    yourIndividualPrice: "Ihr individueller Preis"
  },
  productList: {
    filterLabel: "Filter",
    loadMoreLabel: "Mehr",
    result: "Ergebnisse",
    loading: "Produkte werden geladen...",
    searchPlaceholder: "Übersetzung suchen",
    selectedOccasion: "Anlass auswählen",
    selectedSorting: "Sortieren nach",
    sortingMostPopular: "Beliebteste",
    sortingAbc: "A-Z",
    resetFilter: "Filter zurücksetzen",
    applyBtnLabel: "Anwenden"
  },
  orderDetails: {
    source_file_upload_title: "Sie müssen die Quelldatei hochladen!",
    source_file_upload_description: "Bitte laden Sie jetzt Ihre Dateien hoch, damit wir den perfekten Übersetzer für Ihr Projekt finden können.",
    translator_title: "Ihre Übersetzung ist im Gange!",
    translator_description: "Bitte warten Sie, bis Ihre Übersetzung abgeschlossen ist.",
    corrector_title: "Ihre Übersetzung wird korrigiert!",
    corrector_description: "Bitte warten Sie, bis die Korrektur Ihrer Übersetzung abgeschlossen ist.",
    final_revision_title: "Ihre Übersetzung wird vom Übersetzer überprüft!",
    final_revision_description: "Bitte warten Sie, bis die Überprüfung Ihrer Übersetzung abgeschlossen ist.",
    customer_approval_title: "Ihre Übersetzung ist fertig!",
    customer_approval_description: "Bitte bewerten Sie Ihre Übersetzung unten.",
    shipment_title: "Sie haben keine offene Aufgabe!",
    shipment_description: "Ihr Dokument wird versendet. Sie können Ihre Übersetzung jetzt über die Sendungsverfolgungsnummer verfolgen.",
    pending_title: "Sie haben keine offene Aufgabe!",
    pending_description: "Ihre Bestellung ist (nun) in Bearbeitung",
    delivered_title: "Ihre Bestellung wurde geliefert!",
    delivered_description: "Sie können die Details im folgenden Abschnitt überprüfen.",
  },
  switch_user: "Benutzer wechseln",
  got_it: "Verstanden",
  please_confirm_that_you_want_to_remove_order: "Bitte bestätigen Sie, dass Sie die Bestellung entfernen möchten",
  remove_from_order_list: "Aus Bestellliste entfernen",
  are_you_sure_remove_your_order_list: "Sind Sie sicher, dass sie den Übersetzungsservice aus Ihrer Bestellliste entfernen möchten?",
  order_removed: "Bestellung entfernt",
  proceed_to_order: "Zur Bestellung gehen",
  find_more_products: "Weitere Produkte finden",
  all_states: "Alle Status",
  order_your_translation: "Bestellen Sie Ihre Übersetzung",
  complete_your_order: "Jetzt Bestellung abschließen",
  invalid_captcha: 'Ungültiges captcha',
  profile_updated: 'Dein Profil wurde aktualisiert.',
  request_complete_registration_process: 'Um vollen Zugang zum Kundenbereich zu erhalten, bitten wir Sie freundlich, den Registrierungsprozess abzuschließen. Klicken Sie auf {here}, um den Vorgang abzuschließen.',
  request_complete_registration_process_order: 'Um auf alle Details Ihrer Bestellung zuzugreifen, klicken Sie bitte {here}, um Ihren Registrierungsprozess abzuschließen.',
  request_complete_confirmation_process: 'Ihre E-Mail Adresse wurde nicht bestätigt. Bitte überprüfen Sie Ihren Posteingang und bestätigen Sie sie über den Link, den wir Ihnen geschickt haben.',
  here: 'hier',
  confirmation_successful: 'Bestätigung erfolgreich',
  email_confirmation_successfully_sent: 'E-Mail-Bestätigung erfolgreich gesendet',
  email_confirmation_label: 'Bitte, klicken Sie hier zur Bestätigung',
  payment_error_message: 'Zahlung nicht erfolgreich, bitte überprüfen Sie Ihre Angaben.',

  de: 'Deutsch',
  en: 'Englisch',
  es: 'Spanisch',
  german: 'Deutsch',
  english: 'Englisch',
  spanish: 'Spanisch',
  language: 'Sprache',
  more_languages: 'Weitere Sprachen',
  base_language: 'Beliebte Sprachen',
  edit: 'Bearbeiten',
  save: 'Speichern',
  confirm: 'Bestätigen',
  cancel: 'Abbrechen',
  delete: 'Löschen',
  error: 'Fehler',
  sorry: 'Entschuldigung!',
  yes: 'Ja',
  no: 'Nein',
  login: 'Einloggen',
  email: 'E-Mail',
  password: 'Passwort',
  repeat_password: 'Passwort wiederholen',
  forgot_password: 'Passwort vergessen?',
  error_occurred: 'Ein Fehler ist aufgetreten.',
  back: 'Zurück',
  selectedLabel: 'Ausgewählt',
  selectLabel: 'Auswählen',
  deselectLabel: 'Zum Entfernen eingeben',
  select: 'Wähle ein',
  recommended: 'EMPFOHLEN',
  type_to_search: 'Aus welcher Sprache soll übersetzt werden?',
  no_elements: 'Keine Elemente gefunden. Erwägen Sie, die Suchanfrage zu ändern.',
  empty_list: 'Leere Liste. Beginnen Sie mit der Eingabe, um zu suchen.',
  close: 'Schließen',
  cart_item_remove_cancel: 'Behalten',
  cart_item_remove_confirm: 'Löschen',
  remove_from_cart: 'Aus dem Warenkorb entfernen?',
  remove: 'Entfernen',
  cart_empty: 'Ihr Warenkorb ist leer.',
  link_go_to_products: 'Zu den Produkten',
  link_login: 'Anmelden',
  link_contact: 'Kontakt',
  downloading: 'Herunterladen',
  open_in_backoffice: 'Öffne es im Backoffice',
  download_quote_pdf: 'Angebot PDF herunterladen',
  quote_saved_successfully: 'Angebot erfolgreich gespeichert',
  quote_sent_successfully: 'Das Angebot wurde erfolgreich gesendet',
  save_as_quote: 'Angebot speichern',
  send_email_to_customer: 'Eine E-Mail an den Kunden senden',
  new_translation: 'Neue Übersetzung',
  edit_price: 'Edit price',

  company_name: 'Firmenname',
  company_metadata: 'Firmen-Metadaten',

  from: 'ab',
  included_vat: 'inkl. MwSt',
  included_vat_not: 'ohne MwSt',
  total: 'gesamt',
  number_of_pages: 'Seitenanzahl',
  number_of_words: 'Wortanzahl',
  number_of_fixed_items: 'Menge',
  source_language: 'Ausgangssprache',
  target_language: 'Zielsprache',
  target_languages: 'Zielsprachen',
  source_language_placeholder: 'Aus welcher Sprache soll übersetzt werden?',
  target_language_placeholder: 'In welche Sprache soll übersetzt werden?',
  select_language: 'Wählen Sie eine Sprache',
  select_option: 'Wählen Sie eine Option',
  translation_option: 'Übersetzungsoption',
  delivery_option: 'Art der Lieferung',
  additional_options: 'Zusätzliche Optionen',
  required_fields: 'Bitte überprüfen Sie die unten stehenden Pflichtfelder.',
  add_to_cart: 'In den Warenkorb legen',
  order_now: 'Jetzt bestellen',
  type_of_translation: 'Art der Übersetzung',
  document_upload_after_payment: 'Dateiupload erfolgt nach der Bezahlung',
  translation_details: 'Infos zu Übersetzungen',
  info: 'Informationen',
  refresh_cart: 'Warenkorb aktualisieren',
  not_authorized_title: 'Individueller Service',
  not_authorized: 'Diese individuelle Lösung haben wir speziell für einen unserer Kunden entwickelt und kann nur von diesem gebucht werden. Für weitere Infos zu diesem Service kontaktieren Sie bitte das lingoking Team.',
  included: 'Inbegriffen',
  file_upload: 'Datei-Upload',
  file_upload_label: 'Eine Datei hochladen',
  file_upload_message: 'Zum Schutz Ihrer Daten findet der Datei-Upload erst am Ende des Bestellprozesses statt.',
  drag_and_drop_label: 'oder ziehen und ablegen',
  up_to_label: 'bis zu',
  data_protection_label: 'siehe Datenschutz',
  data_protection_link: 'https://www.lingoking.com/de/allgemeines/datenschutz',
  terms_of_use_link: 'https://www.lingoking.com/de/allgemeines/agb',
  message_to_us: 'Ihre Nachricht an uns (optional)',
  message_to_us_description: 'Wenn Sie uns eine Nachricht senden möchten, z. B. etwas zu Ihrer Bestellung sagen möchten, verwenden Sie bitte das Feld unten.',
  message_to_us_placeholder: 'Ihre Nachricht',
  order_name: 'Auftragsname',
  order_name_description: 'Wenn Sie Ihrer Bestellung einen Namen hinzufügen möchten, geben Sie ihn bitte unten ein.',
  order_name_placeholder: 'Benennen Sie Ihren Auftrag',
  order_updated: 'Bestellung aktualisiert',
  intercom_bot: 'Jetzt Beratung Anfordern',

  pay_safely_with: 'Sicher bezahlen mit',
  your_shopping_cart: 'Ihr Warenkorb',
  item: 'Artikel',
  items: 'Artikel',
  estimated_delivery: 'Voraussichtliche Lieferung',
  digital_delivery_plus_shipping: 'Digitale Lieferung, zzgl. Postversand.',
  summary: 'Zusammenfassung',
  total_amount: 'Gesamtsumme',
  total_amount_net: 'Gesamtsumme netto',
  vat_abbr: 'MwSt.',
  discount: 'Rabatt',
  discount_code: 'Rabattcode',
  discount_apply: 'Anwenden',
  discount_apply_label: 'Haben Sie einen Rabattcode?',
  discount_remove: 'Entfernen',
  discount_message: 'Wenn Sie einen Rabattcode haben, geben Sie diesen unten ein:',
  discount_already_applied: 'Rabatt bereits angewendet! Wenn Sie einen neuen Rabatt verwenden möchten, entfernen Sie den vorherigen.',
  discount_already_applied_summary: 'Rabatt (bereits abgezogen)',
  discount_not_valid: 'Rabatt nicht gefunden oder inaktiv. Bitte überprüfen Sie den Code und versuchen Sie es erneut.',
  discount_usage_reached: 'Die maximale Anzahl von Verwendungen für diesen Rabattcode wurde erreicht.',
  discount_not_applicable: 'Dieser Rabattcode gilt nicht für Produkte in Ihrem Warenkorb.',
  discount_min_value: 'Um diesen Rabatt in Anspruch nehmen zu können, müssen Sie einen Mindestnettopreis von {value} € haben.',
  continue: 'Weiter',
  welcome_back: 'Willkommen zurück',
  new_here: 'Ich bin neu hier',
  register_now: 'Jetzt Registrieren',
  do_you_want_to_create_an_account: 'Möchten Sie ein Konto erstellen?',
  private: 'Privatkunde',
  business: 'Geschäftskunde',
  user_type: 'Benutzertyp',
  first_name: 'Vorname',
  last_name: 'Nachname',
  customer_salutation_mrs: 'Frau',
  customer_salutation_mr: 'Herr',
  your_email: 'E-Mail-Adresse',
  create: 'Registrieren',
  delivery_address: 'Lieferadresse',
  delivery_address_desc: 'Überprüfen Sie die Lieferadresse unten. Dies ist die Lieferadresse für alle Bestellungen mit ausgewählter physischer Lieferoption.',
  billing_address: 'Rechnungsadresse',
  billing_address_desc: 'Überprüfen Sie die Rechnungsadresse unten.',
  same_as_delivery: 'Entspricht meiner Lieferadresse',
  different_billing_address_text: "(Rechnungsadresse im nächsten Schritt angeben)",
  gtc_and_privacy: 'AGB und Datenschutz',
  privacy_policy: 'Datenschutzerklärung',
  imprint: 'Impressum',
  privacy: 'Datenschutz',
  terms_of_use: 'Allgemeinen Geschäftsbedingungen',
  accept_gtc: 'Ich bestätige hiermit, dass ich die {0} sowie die {1} gelesen habe und akzeptiere.',
  accept_gtc_register: 'Wenn Sie einen Account erstellen, stimmen Sie unseren {0} zu. Bitte lesen Sie unsere {1}.',
  pending_form: 'Bitte überprüfen Sie das Formular, bevor Sie fortfahren.',
  name: 'Name',
  address_street_number: 'Straße, Hausnummer',
  number: 'Hausnummer',
  address_street: 'Straße',
  address_zip_code: 'Postleitzahl',
  address_city: 'Stadt',
  address_country: 'Land',
  address_suffix: 'Adresszusatz',
  choose_address_country: 'Wählen Sie ein Land.',
  choose_address_country_placeholder: 'Geben Sie hier ein, um das Land zu suchen',
  payment_method_desc: 'Wählen Sie unten die Zahlungsmethode aus',
  pay_upon_invoice: 'Auf Rechnung bezahlen',
  pay_upon_invoice_description: 'Ausschließlich für Geschäftskunden',
  order_reference: 'Ihre Auftragsreferenz',
  my_order_reference: 'Meine Bestellnummer',
  pay_upon_optional_field: "Optional, erscheint auf Ihrer Rechnung",
  customer_salutation: 'Anrede',
  order_summary: 'Bestellübersicht',
  payment_method: 'Zahlungsmethode',
  you_have_discount: 'Haben Sie einen Rabattcode?',
  submit_order: 'Auftrag absenden',
  submit_order_info: 'Laden Sie die Ausgangsdatei(en) jetzt hoch. Im Anschluss erhalten Sie per E-Mail eine Bestätigung über den Auftragseingang.',
  checkout: 'Jetzt kaufen',
  to_checkout: 'Zur Kasse',
  continue_shopping: 'oder Weiter einkaufen',
  pm_user: 'Sie sind als PM-Benutzer angemeldet. Bitte wählen Sie den Kunden aus, um zur Kasse zu gehen.',
  customer_selection_placeholder: 'Kunden-eMail',
  customer_name: 'Name des Kunden',
  customer_email: 'E-Mail des Kunden',
  customer_selected: 'Kunde ausgewählt',
  select_customer: 'Kunde auswählen',
  change_customer: 'Kunde ändern',
  search: 'Suche',
  set_custom_price: 'Benutzerdefinierten Preis festlegen',
  revert_original_price: 'Zum ursprünglichen Preis zurückkehren',
  price: 'Preis',
  pm_allowed_edit_price: 'Projektleiter können den Auftragspreis manuell bearbeiten',
  next: 'Weiter',

  thanks_for_order: 'Vielen Dank für Ihre Bestellung!',
  upload_files_after_order: 'Dateien hochladen',
  order: 'Bestellung',
  order_created: 'Ihre Bestellung ist bei uns eingegangen. Bitte laden Sie die benötigten Dateien hoch.',
  payment_cancelled: 'Es tut uns Leid! Ihre Bestellung wurde abgebrochen.',
  order_completed_info: ' In Ihrem {0} können Sie alle Details zu Ihren Bestellungen einsehen sowie weitere Einstellungen verwalten.',
  order_created_info: 'Ihre Bestellung ist erstellt! Sie werden zur Kasse weitergeleitet. Wenn dies nicht der Fall ist, klicken Sie auf {0}.',
  payment_processed_info: 'Ihre Zahlung wird bearbeitet. Sie werden zum Dashboard weitergeleitet. Wenn dies nicht der Fall ist, klicken Sie bitte auf {0}.',
  user_area: 'Kundenkonto',

  required: 'Dieses Feld kann nicht leer sein',
  required_min: 'Dieses Feld sollte mindestens {minLength} lang sein',
  same_as_password: 'Der Wert muss gleich dem Passwort sein',
  valid_email: 'Der Wert ist keine gültige E-Mail-Adresse',

  overview_step: 'Übersicht',
  register_step: 'Anmelden',
  address_step: 'Adresse',
  payment_method_step: 'Zahlungsart',
  confirmation_step: 'Bestätigen',
  file_upload_step: 'Hochladen',

  translation_source_file_already_uploaded: 'Es wurden bereits Dateien hochgeladen.',

  upload_after_payment: 'Upload nach Zahlung',
  another_info_message_click_here: 'Weitere Informationsmeldung hier klicken',
  add_to_order_list: 'Zur Bestellliste hinzufügen',
  configuration: 'Konfiguration',
  your_individual_price: 'Ihr individueller Preis (inkl. MwSt.)',
  products_items: '1 Produkt | {n} Produkte'
};
