<template>
  <AlertError :message="errorMessage" @clearMessage="resetError" />
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'

import AlertError from '../alert/AlertError.vue'

export default {
  name: "Error",
  props: ['message'],
  components: {
    AlertError
  },
  setup(props) {
    const store = useStore()
    const { t } = useI18n()

    const error = computed(() => { return store.state.error })

    // Submission Error Message
    const errorMessage = computed(() => {
      if(error.value && error.value.errorType) {
        return t(error.value.errorType.replaceAll('-', '_'))
      }

      if(error.value) {
        return error.value.message || error.value._error
      }

      if(props.message) {
        return props.message
      }

      return null
    })

    const resetError = () => {
      store.commit("SET_ERROR", null)
    }

    return {
      error,
      errorMessage,
      resetError
    }
  }
}
</script>

<style scoped>

</style>
