import axiosInstance from './api'
import CookieService from './cookie'
import TokenService from './token'

let refreshTokenError = false
let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(error = null) {
  isAlreadyFetchingAccessToken = false

  // execute callbacks:
  subscribers = subscribers.filter((callback) => callback(error))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

function formatError(error) {
  const errors = {
    _error:
      error.response.data['hydra:description'] ||
      error.response.data['detail'] ||
      error.response.statusText,
    status: error.response.status,
    statusText: error.response.statusText,
    message:
      error.response.data['message'] ||
      error.response.data['detail'] ||
      error.response.data['hydra:title'],
    errorType: error.response.data['errorType']
  }

  if (error.response.data.violations) {
    error.response.data.violations.map((violation) =>
      Object.assign(errors, {[violation.propertyPath]: violation.message})
    )
    Object.assign(errors, {violations: error.response.data.violations})
  }

  return errors
}

function isCredentialEndpoint(url) {
  return url.includes('/login_check') || url.includes('/token/refresh') || url.includes('/email_confirmation')
}

const setup = (refreshTokenCallback) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalToken()
      const locale = localStorage.getItem('locale')

      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }

      if (locale) {
        config.headers['X-LOCALE'] = locale
      }

      const switchUser = CookieService.getSwitchUser()
      const ignoreSwitchUser = config.ignoreSwitchUser

      if (switchUser && !ignoreSwitchUser) {
        config.headers['X-Switch-User'] = switchUser
      }

      // cache control:
      config.headers['Cache-Control'] = 'must-revalidate, no-store, no-cache, private'

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    async (response) => response,
    async (error) => {
      const originalConfig = error.config

      if (error.request.responseType === 'blob') {
        error.response.data = JSON.parse(await error.response.data.text())
      }

      if (!error.response) {
        return Promise.reject(error)
      }

      if (refreshTokenError) {
        return Promise.reject(formatError(error))
      }

      if (error.response.status === 401 && !isCredentialEndpoint(originalConfig.url)) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true

          refreshTokenCallback()
            .then(() => {
              onAccessTokenFetched()
            })
            .catch((error) => {
              isAlreadyFetchingAccessToken = false
              refreshTokenError = true

              onAccessTokenFetched(error)
            })
        }

        return new Promise((resolve, reject) => {
          addSubscriber((error) => {
            // refresh token error:
            if (error) {
              return reject(error)
            }

            return resolve(axiosInstance(originalConfig))
          })
        })
      }

      return Promise.reject(formatError(error))
    }
  )
}

export default setup
