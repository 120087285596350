export default {
  inputtext: {
    root: ({ props, context }) => ({
      class: [
        'w-full font-sans bg-white',
        'm-0 transition-colors duration-200 appearance-none',
        '!border rounded-[10px]',
        'focus:ring-1 focus:ring-royal-400 outline-none',
        {
          'text-neutral-700 border-neutral-600 hover:border-royal-300': !context.disabled,
          'text-neutral-500 border-neutral-400 placeholder:text-neutral-400 select-none pointer-events-none cursor-default': context.disabled
        },
        {
          'text-lg px-4 py-4': props.size == 'large',
          'text-xs px-2 py-2': props.size == 'small',
          'p-3 text-base': props.size == null
        }
      ]
    }),
  }
}
