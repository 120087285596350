export default {
  namespaced: true,
  state: {
    currentStep: null,
    steps: [
      {id: 1, name: 'overview_step', href: '#', status: 'current'},
      {id: 2, name: 'address_step', href: '#', status: 'upcoming'},
      {id: 3, name: 'payment_method_step', href: '#', status: 'upcoming'},
      {id: 4, name: 'confirmation_step', href: '#', status: 'upcoming'}
    ]
  },
  mutations: {
    setCurrentStep(state, currentStep) {
      state.currentStep = currentStep
    }
  },
  actions: {
    goToStep({commit, state}, step) {
      return new Promise((resolve) => {
        state.steps.find(stepIterator => stepIterator.id === step).status = 'current'

        // Updating Status
        state.steps.forEach(stepIterator => {
          if(stepIterator.id < step) {
            stepIterator.status = 'complete'
          }
          if(stepIterator.id > step) {
            stepIterator.status = 'upcoming'
          }
        })

        commit("setCurrentStep", step)

        resolve()
      })
    },

    nextStep({commit, state}) {
      let nextStep = state.currentStep + 1

      state.steps.find(step => step.id === state.currentStep).status = 'complete'
      state.steps.find(step => step.id === nextStep).status = 'current'

      commit("setCurrentStep", nextStep)
    },

    previousStep({commit, state}) {
      let previousStep = state.currentStep - 1

      state.steps.find(step => step.id === state.currentStep).status = 'upcoming'
      state.steps.find(step => step.id === previousStep).status = 'current'

      commit("setCurrentStep", previousStep)
    },

    completeStep({state}) {
      state.steps.find(stepIterator => stepIterator.id === state.currentStep).status = 'complete'
    }
  },

  getters: {
    currentStep: state => {
      return state.currentStep
    },
    steps: state => {
      return state.steps
    }
  }
}
