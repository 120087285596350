<template>
  <div class="flex flex-col gap-2">
      <label :for="additionalOption.productOptionCombination.productOption.name">{{ additionalOption.productOptionCombination.productOption.label }}</label>
      <Dropdown
        filter
        optionLabel="name"
        class="w-full md:w-14rem"
        v-model="orderOption.productOptionValue"
        :id="additionalOption.productOptionCombination.productOption.name"
        :options="options"
        :placeholder="additionalOption.productOptionCombination.productOption.placeholder">
        <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ slotProps.value }}</div>
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
        </template>
        <template #option="slotProps">
            <div class="flex align-items-center">
                <div>{{ slotProps.option }}</div>
            </div>
        </template>
      </Dropdown>
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue'
import { fetchEntity } from '@/composables/useFetch'

import Dropdown from "primevue/dropdown";

export default {
  name:'MultiSelect',
  props: ['additionalOption', 'optionFormType', 'keyVal', 'label', 'toggleSideOver'],
  components: {
    Dropdown
  },
  setup(props) {
    const orderOption = computed(() => { return props.additionalOption })

    // Options
    const options = ref([])
    const listBoxOptions = ref(null)

    // Hooks
    onMounted(() => {
      prepareOptions()
    })

    // Functions
    function prepareOptions () {
      if(props.optionFormType.options) {
        options.value = props.optionFormType.options
      }

      // if options is not present, load from database:
      if(options.value.length === 0) {
        fetchEntity(orderOption.value.productOptionCombination.productOption.type).then(response => {
          options.value = response.map(function(option) { return option.title || option.name })
        })
      }
    }

    return {
      options,
      orderOption,
      listBoxOptions
    }
  },
}
</script>
