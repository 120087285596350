export default {
  paginator: {
    root: {
      class: [
        "flex items-center justify-center flex-wrap text-sm md:text-md",
        "md:px-4 py-2",
        "border-0",
        "text-neutral-700",
      ],
    },
    firstpagebutton: ({ context }) => ({
      class: [
        "relative",
        // Flex & Alignment
        "inline-flex items-center justify-center",
        // Shape
        "border-0 rounded-full",
        // Size
        "min-w-[2rem] h-8 md:m-[0.143rem]",
        "leading-none",
        // Color
        "text-neutral-700",
        // State
        {
          "hover:bg-royal-100": !context.disabled,
          "focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-400":
            !context.disabled,
        },
        // Transition
        "transition duration-200",
        // Misc
        "user-none overflow-hidden",
        { "cursor-default pointer-events-none opacity-60": context.disabled },
      ],
    }),
    previouspagebutton: ({ context }) => ({
      class: [
        "relative",
        // Flex & Alignment
        "inline-flex items-center justify-center",
        // Shape
        "border-0 rounded-full",
        // Size
        "min-w-[2rem] h-8 md:m-[0.143rem]",
        "leading-none",
        // Color
        "text-neutral-700",
        // State
        {
          "hover:bg-royal-100": !context.disabled,
          "focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-400":
            !context.disabled,
        },
        // Transition
        "transition duration-200",
        // Misc
        "user-none overflow-hidden",
        { "cursor-default pointer-events-none opacity-60": context.disabled },
      ],
    }),
    nextpagebutton: ({ context }) => ({
      class: [
        "relative",
        // Flex & Alignment
        "inline-flex items-center justify-center",
        // Shape
        "border-0 rounded-full",
        // Size
        "min-w-[2rem] h-8 md:m-[0.143rem]",
        "leading-none",
        // Color
        "text-neutral-700",
        // State
        {
          "hover:bg-royal-100": !context.disabled,
          "focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-400":
            !context.disabled,
        },
        // Transition
        "transition duration-200",
        // Misc
        "user-none overflow-hidden",
        { "cursor-default pointer-events-none opacity-60": context.disabled },
      ],
    }),
    lastpagebutton: ({ context }) => ({
      class: [
        "relative",
        // Flex & Alignment
        "inline-flex items-center justify-center",
        // Shape
        "border-0 rounded-full",
        // Size
        "min-w-[2rem] h-8 md:m-[0.143rem]",
        "leading-none",
        // Color
        "text-neutral-700",
        // State
        {
          "hover:bg-royal-100": !context.disabled,
          "focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-400":
            !context.disabled,
        },
        // Transition
        "transition duration-200",
        // Misc
        "user-none overflow-hidden",
        { "cursor-default pointer-events-none opacity-60": context.disabled },
      ],
    }),
    pagebutton: ({ context }) => ({
      class: [
        "relative",
        // Flex & Alignment
        "inline-flex items-center justify-center",
        // Shape
        "border-0 rounded-full",
        // Size
        "min-w-[2rem] h-8 md:m-[0.143rem]",
        "leading-none",
        // Color
        "text-neutral-700",
        {
          "bg-royal-100 text-royal-400": context.active,
        },
        // State
        {
          "hover:bg-royal-100": !context.disabled && !context.active,
          "focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-400":
            !context.disabled,
        },
        // Transition
        "transition duration-200",
        // Misc
        "user-none overflow-hidden",
        { "cursor-default pointer-events-none opacity-60": context.disabled },
      ],
    }),
    rowperpagedropdown: {
      root: ({ props, state }) => ({
        class: [
          // Display and Position
          "inline-flex",
          "relative",
          // Shape
          "rounded-md",
          // Spacing
          // Color and Background
          "bg-white",
          "border border-neutral-400",
          // Transitions
          "transition-all",
          "duration-200",
          // States
          "hover:border-primary-500",
          {
            "focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-400":
              !state.focused,
          },
          // Misc
          "cursor-pointer",
          "select-none",
          {
            "opacity-60": props.disabled,
            "pointer-events-none": props.disabled,
            "cursor-default": props.disabled,
          },
        ],
      }),
      input: {
        class: [
          "font-sans",
          "leading-5",
          "block",
          "flex-auto",
          "bg-transparent",
          "border-0",
          "text-neutral-800",
          "w-[1%]",
          "py-2 px-3 pr-0",
          "rounded-none",
          "transition",
          "duration-200",
          "focus:outline-none focus:shadow-none",
          "relative",
          "cursor-pointer",
          "overflow-hidden overflow-ellipsis",
          "whitespace-nowrap",
          "appearance-none",
        ],
      },
      trigger: {
        class: [
          "flex items-center justify-center",
          "shrink-0",
          "bg-transparent",
          "text-neutral-700",
          "w-8",
          "rounded-tr-md",
          "rounded-br-md",
        ],
      },
      panel: {
        class: [
          "absolute top-0 left-0",
          "border-0",
          "rounded-md",
          "shadow-md",
          "bg-royal-100",
          "text-neutal-800",
        ],
      },
      wrapper: {
        class: ["max-h-[200px]", "overflow-auto"],
      },
      list: {
        class: "py-1 list-none m-0",
      },
      item: ({ context }) => ({
        class: [
          // Font
          "font-normal",
          "leading-none",
          // Position
          "relative",
          // Shape
          "border-0",
          "rounded-none",
          // Spacing
          "m-0",
          "py-3 px-5",
          "hover:bg-white",
          // Transitions
          "transition-shadow",
          "duration-200",
          // Misc
          "cursor-pointer",
          "overflow-hidden",
          "whitespace-nowrap",
        ],
      }),
    },
    jumptopageinput: {
      root: {
        class: "inline-flex mx-2",
      },
      input: {
        root: {
          class: [
            "relative",
            "font-sans",
            "leading-none",
            "block",
            "flex-auto",
            "text-neutal-600",
            "placeholder:text-neutal-400",
            "bg-neutal-0 ",
            "border border-neutal-300",
            "w-[1%] max-w-[3rem]",
            "p-3 m-0",
            "rounded-md",
            "transition",
            "duration-200",
            "hover:border-primary-500",
            "focus:outline-none focus:shadow-none",
            "focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50",
            "cursor-pointer",
            "overflow-hidden overflow-ellipsis",
            "whitespace-nowrap",
            "appearance-none",
          ],
        },
      },
    },
    jumptopagedropdown: {
      root: ({ props, state }) => ({
        class: [
          // Display and Position
          "inline-flex",
          "relative",
          // Shape
          "h-12",
          "rounded-md",
          // Color and Background
          "bg-neutal-0",
          "border border-neutal-300 ",
          // Transitions
          "transition-all",
          "duration-200",
          // States
          "hover:border-primary-500",
          {
            "focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-400":
              !state.focused,
          },
          // Misc
          "cursor-pointer",
          "select-none",
          {
            "opacity-60": props.disabled,
            "pointer-events-none": props.disabled,
            "cursor-default": props.disabled,
          },
        ],
      }),
      input: {
        class: [
          "font-sans",
          "leading-none",
          "block",
          "flex-auto",
          "bg-transparent",
          "border-0",
          "text-neutal-800",
          "w-[1%]",
          "p-3",
          "rounded-none",
          "transition",
          "duration-200",
          "focus:outline-none focus:shadow-none",
          "relative",
          "cursor-pointer",
          "overflow-hidden overflow-ellipsis",
          "whitespace-nowrap",
          "appearance-none",
        ],
      },
      trigger: {
        class: [
          "flex items-center justify-center",
          "shrink-0",
          "bg-transparent",
          "text-neutral-700",
          "w-12",
          "rounded-tr-md",
          "rounded-br-md",
        ],
      },
      panel: {
        class: [
          "absolute top-0 left-0",
          "border-0",
          "rounded-md",
          "shadow-md",
          "bg-neutal-0",
          "text-neutal-800",
        ],
      },
      wrapper: {
        class: ["max-h-[200px]", "overflow-auto"],
      },
      list: {
        class: "py-3 list-none m-0",
      },
      item: ({ context }) => ({
        class: [
          // Font
          "font-normal",
          "leading-none",
          // Position
          "relative",
          // Shape
          "border-0",
          "rounded-none",
          // Spacing
          "m-0",
          "py-3 px-5",
          // Color
          {
            "text-neutal-700": !context.focused && !context.selected,
          },
          {
            "bg-royal-100 text-neutal-700":
              context.focused && !context.selected,
          },
          {
            "bg-primary-100 text-primary-700":
              context.focused && context.selected,
          },
          {
            "bg-primary-50 text-primary-700":
              !context.focused && context.selected,
          },
          //States
          {
            "hover:bg-neutal-100":
              !context.focused && !context.selected,
          },
          {
            "hover:text-neutal-700 hover:bg-neutal-100":
              context.focused && !context.selected,
          },
          // Transitions
          "transition-shadow",
          "duration-200",
          // Misc
          "cursor-pointer",
          "overflow-hidden",
          "whitespace-nowrap",
        ],
      }),
    },
  },
};
