export default {
  radiobutton: {
    root: {
      class: ["relative inline-flex cursor-pointer align-bottom", "w-6 h-6"],
    },
    input: ({ props, state }) => ({
      class: [
        "peer",
        "w-full ",
        "h-full",
        "absolute",
        "top-0 left-0",
        "z-10",
        "p-0",
        "m-0",
        "opacity-0",
        "rounded-md",
        "outline-none",
        "appareance-none",
        "cursor-pointer",
        "group",
        "border border-neutral-800",
        {
          "border-royal-300 ring-1 ring-royal-400":
            state.focused && !props.disabled,
          "cursor-default opacity-60": props.disabled,
        },
      ],
    }),
    icon: ({ props }) => ({
      class: [
        "transform rounded-full",
        "block w-3 h-3 transition duration-200",
        {
          "group-hover:bg-royal-300": !props.disabled,
          "transform scale-100 bg-neutral-800": props.value == props.modelValue,
        },
      ],
    }),
    box: ({ props: e }) => ({
      class: [
        "flex justify-center items-center",
        "w-[1.571rem] h-[1.571rem]",
        "border-1",
        "rounded-full",
        "transition duration-200 ease-in-out",
        "border-neutral-700",
        {
          "peer-hover:border-primary-500":
            !e.disabled,
          "peer-hover:border-primary-600 peer-hover:bg-primary-600":
            !e.disabled && e.value == e.modelValue && e.value !== void 0,
          "peer-focus-visible:border-primary-500 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20":
            !e.disabled,
          "opacity-60 cursor-default": e.disabled,
        },
      ],
    }),
  },
};
