export default {
  chips: {
    root: ({ props }) => ({
      class: [
        'flex',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled
        }
      ]
    }),
    container: {
      class: [
        'm-0 py-1.5 px-3 list-none cursor-text overflow-hidden flex items-center flex-wrap',
        'w-full',
        'font-sans text-base text-neutral-600 bg-white p-3 border border-neutral-300 transition-colors duration-200 appearance-none rounded-lg',
        'hover:border-royal-400 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
      ]
    },

    inputtoken: {
      class: ['py-1.5 px-0', 'flex flex-1 inline-flex']
    },
    input: {
      class: [
        'font-sans text-base text-neutral-700 p-0 m-0',
        'border-0 outline-none bg-transparent shadow-none rounded-none w-full'
      ]
    },
    token: {
      class: [
        'py-1 px-2 mr-2 bg-neutral-300 text-neutral-700 rounded-full',
        'cursor-default inline-flex items-center'
      ]
    },
    removeTokenIcon: 'ml-2'
  }
}
