export default {
  fileupload: {
    root: {
      class: "border border-royal-200 rounded-xl bg-white",
    },
    input: {
      class: "hidden",
    },
    buttonbar: {
      class: [],
    },
    chooseButton: {
      class: [
        "relative",
        "items-center inline-flex text-center align-bottom justify-center",
        "px-4 py-3",
        "rounded-md",
        "leading-[normal]",
        "font-bold",
        "text-white",
        "bg-primary-500",
        "border-primary-500",
        "hover:bg-primary-600",
        "overflow-hidden",
        "cursor-pointer",
      ],
    },
    chooseIcon: {
      class: ["mr-2", "inline-block"],
    },
    chooseButtonLabel: {
      class: ["flex-1", "font-bold"],
    },
    uploadbutton: {
      icon: {
        class: "mr-2",
      },
    },
    cancelbutton: {
      icon: {
        class: "mr-2",
      },
    },
    content: {
      class: ["p-2 bg-white rounded-b-xl"],
    },
    file: {
      class: [
        "flex",
        "items-center",
        "flex-wrap",
        "p-4",
        "mb-2",
        "last:mb-0",
        "border",
        "border-surface-200",
        "gap-2",
        "rounded",
      ],
    },
    thumbnail: {
      class: "shrink-0",
    },
    fileName: {
      class: "mb-2",
    },
    fileSize: {
      class: "mr-2",
    },
    uploadicon: {
      class: "mr-2",
    },
    progressbar: {
      root: {
        class: [
          "overflow-hidden",
          "absolute top-0 left-0",
          "border-0",
          "h-2",
          "rounded-md",
          "w-full",
          "bg-surface-100",
        ],
      },
      value: {
        class: [
          "absolute flex items-center justify-center overflow-hidden",
          "bg-primary-500",
          "m-0",
          "h-full w-0",
          "border-0",
          "transition-width duration-1000 ease-in-out",
        ],
      },
    },
  },
};
