import {VueCookieNext} from "vue-cookie-next";
import {v4 as uuidv4} from "uuid";

import CookieNames from "../enums/CookieNames";

VueCookieNext.config({
  domain: window.lingokingConfig.URL_FQDN,
  expire: '60d'
})

class CookieService {
  getCookie(cookieName) {
    return VueCookieNext.getCookie(getCookieNameWithHash(cookieName))
  }

  setCookie(cookieName, value, options = null) {
    VueCookieNext.setCookie(getCookieNameWithHash(cookieName), value, options)
  }

  removeCookie(cookieName) {
    VueCookieNext.removeCookie(getCookieNameWithHash(cookieName))
  }

  removeAllCookies(removeLogin = true) {
    let dataCookies = VueCookieNext
      .keys()
      .filter(cookie => cookie !== getCookieNameWithHash(CookieNames.LK_TOKEN))

    dataCookies.forEach(cookie => VueCookieNext.removeCookie(cookie))

    if (removeLogin) {
      VueCookieNext.removeCookie(getCookieNameWithHash(CookieNames.LK_TOKEN))
    }
  }

  getSwitchUser() {
    return this.getCookie(CookieNames.SWITCH_USER)
  }

  setSwitchUser(email) {
    this.setCookie(CookieNames.SWITCH_USER, email)
  }

  removeSwitchUser() {
    this.removeCookie(CookieNames.SWITCH_USER)
  }

  getCartId() {
    return this.getCookie(CookieNames.CART_ID)
  }

  setCartId(id = null) {
    if (!id) {
      id = uuidv4()
    }

    const anonymousCartCookie = this.getCartId()

    if (!anonymousCartCookie) {
      this.setCookie(CookieNames.CART_ID, id, {expire: 0, secure: true})
    }

    return this.getCartId()
  }

  removeCartId() {
    this.removeCookie(CookieNames.CART_ID)
  }
}

function getCookieNameWithHash(cookieName) {
  return cookieName + '-' + window.lingokingConfig.MM_ENVIRONMENT
}

export default new CookieService();
