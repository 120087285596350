import Timezones from "../utils/timezones";

let ProductOptionFormType = {
  // Input
  RADIO:
    {
      "type": "radio",
      "componentType": "Input",
      "formType": "radio"
    },
  TEXT_INPUT:
    {
      "type": "text_input",
      "componentType": "Input",
      "formType": "text"
    },
  CHECKBOX:
    {
      "type": "checkbox",
      "componentType": "Input",
      "formType": "checkbox"
    },
  NUMBERS_INPUT:
    {
      "type": "number",
      "componentType": "Input",
      "formType": "number",
      "step": 1,
      "min": 0,
      "max": 1000
    },
  NUMBERS_INPUT_MAX_10:
    {
      "type": "number_max_10",
      "componentType": "Input",
      "formType": "number",
      "step": 1,
      "min": 0,
      "max": 10
    },
  PHONE_NUMBER_INPUT:
    {
      "type": "phone",
      "componentType": "Input",
      "formType": "tel"
    },

  // DatePicker
  DATE_TIME_INPUT:
    {
      "type": "date_time",
      "componentType": "DatePicker",
      "formType": null
    },
  START_END_DATE_INPUTS:
    {
      "type": "start_end_date",
      "componentType": "DatePickerStartEnd",
      "formType": null
    },

  // MultiSelect
  TOPIC_DROPDOWN :
    {
      "type": "topics",
      "componentType": "MultiSelect",
      "formType": null
    },
  COUNTRY_DROPDOWN:
    {
      "type": "countries",
      "componentType": "MultiSelect",
      "formType": null
    },
  TIMEZONE_DROPDOWN:
    {
      "type": "timezones",
      "componentType": "MultiSelect",
      "formType": null,
      "options": Timezones.map(timezone => {
        return timezone.value
      })
    }
}

export default ProductOptionFormType
