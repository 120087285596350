<template>
  <component
    :class="[
      'border border-royal-200 bg-white rounded-2xl flex w-full h-full font-sans',
      orientation === 'vertical' ? 'flex-col' : 'flex-col md:flex-row',
      productURI ? 'hover:shadow-lg' : ''
    ]"
    :is="productURI ? 'a' : 'div'"
    :href="productURI"
  >
    <img
      v-if="productImage"
      :src="productImage"
      :class="[
        'object-cover object-center rounded-t-2xl w-full h-[200px]',
        orientation === 'vertical' ? 'rounded-t-2xl w-full h-[200px]' : '',
        orientation === 'horizontal'
          ? 'md:rounded-tr-none md:rounded-l-2xl md:w-[252px] md:h-auto'
          : '',
        orientation === 'horizontal-small'
          ? 'md:rounded-tr-none md:rounded-l-2xl md:w-[100px] md:h-auto'
          : ''
      ]"
    />
    <span
      v-else
      class="iconlk-languages text-8xl text-royal-200 bg-royal-100"
      :class="[
        'flex justify-center items-center rounded-t-2xl w-full h-[200px]',
        orientation === 'vertical' ? 'rounded-t-2xl w-full h-[200px]' : '',
        orientation === 'horizontal'
          ? 'md:rounded-tr-none md:rounded-l-2xl md:w-[252px] md:h-auto'
          : '',
        orientation === 'horizontal-small'
          ? 'md:rounded-tr-none md:rounded-l-2xl md:w-[100px] md:h-auto'
          : ''
      ]"
    ></span>
    <div :class="['flex flex-col grow']">
      <div class="grow">
        <div
          :class="[
            'p-4 md:p-5 flex gap-4 flex-wrap-reverse md:flex-nowrap md:gap-1',
            $slots.actionButtons ? 'flex-row justify-between' : 'flex-col'
          ]"
        >
          <div class="flex flex-col gap-1">
            <Skeleton height="1.5rem" v-if="!order.product"/>
            <div v-else>
              <h5 class="text-xl text-neutral-800 !mb-0">{{ order.product.name }}</h5>
            </div>
            <Skeleton height="3rem" v-if="!order.product"/>
            <div v-else>
              <p v-html="order.product.shortDescription" class="text-sm text-neutral-700"></p>
            </div>
          </div>
          <div v-if="$slots.actionButtons" class="flex flex-row gap-[22px]">
            <slot name="actionButtons"></slot>
          </div>
        </div>

        <div class="px-4 pb-4 pt-0 md:px-5 md:pb-5 flex flex-col items-start gap-5">
          <div v-if="order.product" class="flex flex-wrap gap-3 text-neutral-700 text-sm">
            <Chip class="p-1 gap-x-1 bg-neutral-200">
              <span class="iconlk-file-text w-4 h-4"></span>
              <span> {{ order.quantity }} {{ getPriceUnitLabel(order.product) }} </span>
            </Chip>
            <div v-if="order.sourceLanguage">
              <Chip
                class="p-1 gap-x-1 bg-neutral-200"
                v-for="targetLanguage in order.targetLanguages"
                :key="targetLanguage.id"
              >
                <span class="iconlk-stamp w-4 h-4"></span>
                <span>
                {{ order.sourceLanguage.name }} <span>&#8594;</span> {{ targetLanguage.name }}
              </span>
              </Chip>
            </div>
            <Chip v-if="order.selectedTranslationOption" class="p-1 gap-x-1 bg-neutral-200">
              <span class="iconlk-languages w-4 h-4"></span>
              <span>
                {{ order.selectedTranslationOption.productOption.label }}
              </span>
            </Chip>
            <Chip v-if="order.selectedDeliveryOption" class="p-1 gap-x-1 bg-neutral-200">
              <span class="iconlk-car-delivering w-4 h-4"></span>
              <span>
                {{ order.selectedDeliveryOption.productOption.label }}
              </span>
            </Chip>

            <Chip
              v-for="additionalOption in additionalOptionsWithValues"
              :key="additionalOption.productOptionCombination.id"
              class="p-1 gap-x-1 bg-neutral-200">
              <span class="iconlk-settings w-4 h-4"></span>
              <span>
                {{ additionalOption.productOptionCombination.productOption.label }}
                <span v-if="additionalOption.productOptionValue !== 'true'">
                  <span>&#8594;</span>
                  {{ additionalOption.productOptionValue }}
                </span>
              </span>
            </Chip>
          </div>

          <!-- Delivery Date -->
          <Skeleton height="1.5rem" v-if="!order.product"/>
          <div
            v-else-if="order.selectedDeliveryOption"
            class="flex space-x-1 items-center text-sm text-neutral-700"
          >
            <span class="iconlk-calendar text-lg"/>
            <p>
              {{ getDeliveryTime(order.selectedDeliveryOption.productOption.name).join(' - ') }}*
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="showFooter"
        :class="[
          orderPrice ? 'bg-neutral-700 text-white' : 'bg-neutral-100 text-neutral-700',
          orientation === 'vertical'
            ? 'rounded-b-2xl'
            : 'rounded-b-2xl md:rounded-br-2xl md:rounded-bl-none'
        ]"
      >
        <div v-if="!order.product" class="px-5 py-2 md:px-5 md:py-4 h-16">
          <Skeleton height="1.5rem"/>
        </div>
        <div v-else class="flex justify-between items-center px-5 py-2 md:px-5 md:py-4 h-16">
          <p class="text-base">
            {{ $t(orderPrice ? 'your_individual_price' : 'price') }}
          </p>
          <div v-if="!orderPrice">
            <div v-if="isLoading" class="h-8 w-8">
              <ProgressSpinner class="h-full w-full"/>
            </div>
            <p v-else class="text-lg font-sans">€{{ order.product.marketingPrice }}</p>
          </div>
          <div v-else>
            <div v-if="isLoading" class="h-8 w-8">
              <ProgressSpinner class="h-full w-full"/>
            </div>
            <Tag v-else :value="orderPrice" severity="secondary" rounded/>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {isHoliday} from 'feiertagejs'

import Chip from 'primevue/chip'
import Tag from 'primevue/tag'
import ProgressSpinner from 'primevue/progressspinner';
import Skeleton from 'primevue/skeleton'

import useOptions from '@/composables/useOptions'
import DeliveryTime from '@/enums/DeliveryTime'

export default {
  name: 'ProductCard',
  components: {
    Chip,
    Tag,
    ProgressSpinner,
    Skeleton
  },
  props: {
    order: Object,
    productImage: String,
    deliveryDates: Object,
    translations: Object,
    showTags: Boolean,
    showFooter: Boolean,
    isIndividualPrice: Boolean,
    orientation: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical', 'horizontal-small'].includes(value)
    }
  },
  setup(props) {
    const store = useStore()
    const {n, locale} = useI18n()
    const {getPriceUnitLabel} = useOptions()

    const additionalOptionsWithValues = computed(() => {
      if (!props.order.product) {
        return []
      }

      return props.order.additionalOptions.filter((additionalOption) => {
        let isFalseSet = (additionalOption.productOptionValue === 'false')

        return !isFalseSet && additionalOption.productOptionValue
      })
    })

    const calculatedOrder = computed(() => {
      return store.getters['order/calculatedOrder']
    })

    const isLoading = computed(() => {
      return store.state.isLoading
    })

    const orderPrice = computed(() => {
      if (calculatedOrder.value.totalPrice) {
        return n(parseFloat(calculatedOrder.value.totalPrice), 'currency', locale.value)
      }

      return null
    })

    const marketingPrice = computed(() => {
      if (props.order.product.value.marketingPrice && !calculatedOrder.value.totalPrice) {
        return n(parseFloat(props.order.product.value.marketingPrice), 'currency', locale.value)
      }

      return null
    })

    const productURI = computed(() => {
      if (props.order.product) {
        return props.order.product.uri
      }

      return null
    })

    const getDeliveryTime = (deliveryOption) => {
      let dates = []

      DeliveryTime[deliveryOption].forEach((businessDays) => {
        let estimateWeekDay = getBusinessDateFromDate(new Date(), businessDays).toLocaleDateString(
          locale.value,
          {weekday: 'short'}
        )
        let estimateDate = getBusinessDateFromDate(new Date(), businessDays).toLocaleDateString(
          locale.value
        )

        dates.push(estimateWeekDay + ', ' + estimateDate)
      })

      return dates
    }

    const getBusinessDateFromDate = (date, businessDays) => {
      let counter = 0
      let tmp = new Date(date)

      while (businessDays >= 0) {
        tmp.setTime(date.getTime() + counter * 86400000)

        if (isBusinessDay(tmp)) {
          --businessDays
        }

        ++counter
      }

      return tmp
    }

    const isBusinessDay = (date) => {
      let dayOfWeek = date.getDay()

      if (dayOfWeek === 0 || dayOfWeek === 6 || isHoliday(date, 'ALL')) {
        return false
      }

      return true
    }

    return {
      additionalOptionsWithValues,
      isLoading,
      orderPrice,
      marketingPrice,
      productURI,
      getPriceUnitLabel,
      getDeliveryTime
    }
  }
}
</script>
