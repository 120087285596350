import {Buffer} from 'buffer';
import { jwtDecode } from "jwt-decode";

import CookieService from './cookie'
import CookieNames from "../enums/CookieNames";

class TokenService {
  setTokenName(tokenName) {
    this.tokenName = tokenName;
  }

  getTokenName() {
    if (this.tokenName) {
      return this.tokenName;
    }
    return CookieNames.LK_TOKEN;
  }

  setAccessToken(data) {
    const buffer = Buffer.from(JSON.stringify(data), 'utf-8');
    const base64 = buffer.toString('base64');

    CookieService.setCookie(this.getTokenName(), base64)
  }

  getAccessToken() {
    let accessTokenCookie = CookieService.getCookie(this.getTokenName())

    if (accessTokenCookie) {
      const buffer = Buffer.from(accessTokenCookie, 'base64');
      const accessToken = buffer.toString('utf-8');

      return JSON.parse(accessToken)
    }
  }

  getLocalToken(options) {
    return this.getAccessToken(options) ? this.getAccessToken(options).token : null
  }

  getLocalRefreshToken() {
    return this.getAccessToken() ? this.getAccessToken().refresh_token : null
  }

  getTokenData() {
    return this.getAccessToken() ? jwtDecode(this.getAccessToken().token) : null
  }

  removeAccessToken() {
    CookieService.removeCookie(this.getTokenName())
  }

  updateLocalAccessToken(data) {
    CookieService.removeCookie(this.getTokenName())

    this.setAccessToken(data)
  }
}

export default new TokenService();
