import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";

const sentry = {
    install(app, entrypoint) {
        Sentry.init({
            app,
            dsn: entrypoint.SENTRY_DSN,
            attachProps: true,
            logErrors: true,
            integrations: [
                new BrowserTracing({
                    tracingOrigins: [entrypoint.ENTRYPOINT, "lingoking.com", "localhost", /^\//],
                }),
            ],
            tracesSampleRate: entrypoint.SENTRY_SAMPLERATE,
            trackComponents: true,
            environment: entrypoint.SENTRY_ENV,
            release: entrypoint.SENTRY_RELEASE,
        });
    }
}

export default sentry
