export default {
  de: 'Alemán',
  en: 'Inglés',
  es: 'Español',
  german: 'Alemán',
  english: 'Inglés',
  spanish: 'Español',
  language: 'Idioma',
  more_languages: 'Más idiomas',
  base_language: 'Idiomas populares',
  edit: 'Editar',
  save: 'Guardar',
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  delete: 'Borrar',
  error: 'Error',
  sorry: '¡Lo siento!',
  yes: 'Sí',
  no: 'No',
  login: 'Iniciar sesión',
  email: 'Correo electrónico',
  password: 'Contraseña',
  repeat_password: 'Repita la contraseña',
  forgot_password: '¿Olvidó su contraseña?',
  error_occurred: 'Ha ocurrido un error.',
  back: 'Atrás',
  selectedLabel: 'Seleccionado',
  selectLabel: 'Seleccione',
  deselectLabel: 'Presione enter para eliminar',
  select: 'Escoge un',
  recommended: 'RECOMENDADO',
  type_to_search: '¿De qué idioma desea traducir?',
  no_elements: 'No se encontraron elementos. Considere cambiar la consulta de búsqueda.',
  empty_list: 'Lista vacía. Comienza a escribir para buscar.',
  close: 'Cerrar',
  cart_item_remove_cancel: 'Mantener',
  cart_item_remove_confirm: 'Borrar',
  remove_from_cart: '¿Quitar del carrito?',
  cart_empty: 'Su cesta de la compra está vacía.',
  link_go_to_products: 'Ir a productos',
  link_login: 'Iniciar sesión',
  link_contact: 'Contacto',
  downloading: 'Descargando',
  open_in_backoffice: 'Ábrelo en el backoffice',
  download_quote_pdf: 'Descargar presupuesto pdf',
  quote_saved_successfully: 'Cotización guardada exitosamente',
  save_as_quote: 'Guardar como cotización',
  send_email_to_customer: 'Enviar un correo electrónico al cliente',

  edit_price: 'Edit price',

  company_name: 'Nombre de la empresa',
  company_metadata: 'Metadatos de la empresa',

  from: 'de',
  included_vat: 'incl. IVA',
  included_vat_not: 'no incl. IVA',
  total: 'total',
  number_of_pages: 'Número de páginas',
  number_of_words: 'Número de palabras',
  number_of_fixed_items: 'Cantidad',
  source_language: 'Idioma de origen',
  target_language: 'Idioma de destino',
  source_language_placeholder: '¿De qué idioma desea traducir?',
  target_language_placeholder: '¿A qué idioma desea traducir?',
  target_languages: 'Idiomas de destino',
  select_language: 'Seleccione el idioma',
  select_option: 'Seleccione una opción',
  translation_option: 'Opción de traducción',
  delivery_option: 'Opción de entrega',
  additional_options: 'Opciones Adicionales',
  required_fields: 'Por favor, marque los campos obligatorios a continuación.',
  add_to_cart: 'Añadir al carrito',
  order_now: 'Ordenar ahora',
  type_of_translation: 'Tipo de traducción',
  info: 'Información',
  refresh_cart: 'Actualizar mi Carrito de Compras',
  not_authorized_title: 'Servicio personalizado',
  not_authorized: 'Hemos desarrollado esta solución individual especialmente para uno de nuestros clientes y sólo puede ser comprada por este cliente.  Para más información sobre este servicio, por favor, contacte a lingoking.',
  included: 'Incluido',
  file_upload: 'Carga de archivos',
  file_upload_label: 'Cargar un archivo',
  file_upload_message: 'Para proteger sus datos, la carga de archivos tiene lugar únicamente al final del proceso de pedido.',
  drag_and_drop_label: 'o arrastrar y soltar',
  up_to_label: 'hasta',
  data_protection_label: 'véase la protección de datos',
  data_protection_link: 'https://www.lingoking.com/es/general/proteccion-de-datos',
  terms_of_use_link: 'https://www.lingoking.com/es/general/terminos-y-condiciones-de-uso',
  message_to_us: 'Su mensaje para nosotros (opcional)',
  message_to_us_description: 'Utilice el campo a continuación si desea enviarnos un mensaje, como algo sobre su pedido.',
  message_to_us_placeholder: 'Tu mensaje',
  order_name: 'Nombre de Orden',
  order_name_description: 'Si desea poner un nombre en su pedido, colóquelo a continuación.',
  order_name_placeholder: 'Nombre de su pedido',
  order_updated: 'Pedido actualizado',
  intercom_bot: 'Solicite Asesoramiento Ahora',

  pay_safely_with: 'Pago seguro con',
  your_shopping_cart: 'Tu Carrito de la Compra',
  item: 'Artículo',
  items: 'Artículos',
  estimated_delivery: 'Entrega Estimada',
  digital_delivery_plus_shipping: 'Entrega digital, seguida de envío por correo',
  summary: 'Resumen',
  total_amount: 'Gran total',
  total_amount_net: 'Total neto',
  vat_abbr: 'VAT',
  discount: 'Descuento',
  discount_code: 'Código de Descuento',
  discount_apply: 'Aplicar',
  discount_apply_label: '¿Tiene un código de descuento?',
  discount_remove: 'Eliminar',
  discount_message: 'Si tiene un código de descuento, ingréselo a continuación:',
  discount_already_applied: '¡Descuento ya aplicado! Si desea utilizar un nuevo descuento, elimine el anterior.',
  discount_already_applied_summary: 'Descuento (ya aplicado)',
  discount_not_valid: 'Descuento no encontrado o inactivo. Comprueba el código y vuelve a intentarlo.',
  discount_usage_reached: 'Se alcanzó el número máximo de usos para este código de descuento.',
  discount_not_applicable: 'Este código de descuento no se aplica a ninguno de los productos de su carrito de compras.',
  discount_min_value: 'Para aplicar este descuento debe tener un precio neto mínimo de {value} €.',
  continue: 'Continuar',
  welcome_back: 'Bienvenido de Nuevo',
  new_here: 'Soy nuevo aqui',
  register_now: 'Regístrese ahora',
  private: 'Privado',
  business: 'Comercial',
  user_type: 'Tipo de Usuario',
  first_name: 'Nombre de pila',
  last_name: 'Apellido',
  customer_salutation_mrs: 'Sra',
  customer_salutation_mr: 'Sr',
  your_email: 'Correo Electrónico',
  create: 'Crear',
  delivery_address: 'Dirección de Entrega',
  delivery_address_desc: 'Verifique la dirección de envío a continuación. Esta es la dirección de entrega para todos los pedidos con la opción de entrega física seleccionada.',
  billing_address: 'Dirección de Envio',
  billing_address_desc: 'Verifique la dirección de facturación a continuación.',
  same_as_delivery: 'Lo mismo que la dirección de entrega',
  gtc_and_privacy: 'GTC y protección de datos',
  privacy_policy: 'Política de Privacidad',
  terms_of_use: 'Términos y Condiciones Generales',
  accept_gtc: 'Confirmo que he leído y comprendido los {0} y la {1}, los cuales acepto.',
  accept_gtc_register: 'Cuando crea una cuenta, acepta nuestros {0}. Por favor, lea nuestra {1}.',
  pending_form: 'Por favor revise el formulario antes de continuar.',
  name: 'Nombre',
  address_street_number: 'Calle, número',
  address_street: 'Calle',
  number: 'Número',
  address_zip_code: 'Código postal',
  address_city: 'Ciudad',
  address_country: 'País',
  address_suffix: 'Sufijo de la dirección',
  choose_address_country: 'Seleccione un país.',
  choose_address_country_placeholder: 'Escriba aquí para buscar el país',
  payment_method_desc: 'Elija el método de pago a continuación',
  pay_upon_invoice: 'Pagar Sobre Factura',
  pay_upon_invoice_description: 'Pago pospago de Lingoking exclusivamente a nuestros clientes comerciales.',
  order_reference: 'Su Referencia de Pedido',
  my_order_reference: 'referencia de mi pedido',
  pay_upon_optional_field: "optativo, se mostrará en su factura",
  customer_salutation: 'Saludo',
  submit_order: 'Enviar pedido',
  submit_order_info: 'Suba ahora el/los archivo(s) de origen. A continuación, recibirá un correo electrónico confirmando la recepción del pedido.',
  checkout: 'Compra ahora',
  to_checkout: 'Ir a caja',
  continue_shopping: 'o Seguir comprando',
  pm_user: 'Ha iniciado sesión como usuario de PM. Seleccione el cliente para proceder al pago.',
  customer_selection_placeholder: 'Correo Electrónico del Cliente',
  customer_name: 'Nombre del cliente',
  customer_email: 'Correo electrónico del cliente',
  customer_selected: 'cliente seleccionado',
  select_customer: 'Seleccionar cliente',
  change_customer: 'Cambiar cliente',
  search: 'Buscar',
  set_custom_price: 'Fijar precio personalizado',
  revert_original_price: 'Volver al precio original',
  price: 'Precio',
  pm_allowed_edit_price: 'Los jefes de proyecto pueden editar manualmente el precio del pedido',
  profile_updated: 'Su perfil ha sido actualizado.',
  thanks_for_order: '¡Gracias por su pedido!',
  order: 'Pedido',
  order_created: 'Su pedido ha sido creado. Por favor, suba los archivos necesarios.',
  payment_cancelled: '¡Lo sentimos! Por favor, elija otro método de pago.',
  order_completed_info: 'En su {0} puede ver todos los detalles de sus pedidos y gestionar otros ajustes.',
  user_area: 'cuenta de cliente',

  required: 'Este campo no puede estar vacío',
  required_min: 'Este campo debe tener al menos {minLength} de largo',
  same_as_password: 'El valor debe ser igual a la contraseña.',
  valid_email: 'El valor no es una dirección de correo electrónico válida',

  overview_step: 'Resumen',
  register_step: 'Registrarse',
  address_step: 'Habla a',
  payment_method_step: 'Método de Pago',
  confirmation_step: 'Confirmación',
  file_upload_step: 'Subir',

  translation_source_file_already_uploaded: 'Los archivos ya se han cargado.',

  upload_after_payment: 'Subir después del pago',
  another_info_message_click_here: 'Otro mensaje de información, haz clic aquí',
  add_to_order_list: 'Agregar a la lista de pedidos',
  configuration: 'Configuración',
  your_individual_price: 'Tu precio individual',
};
