import { createApp } from 'vue'
import { sentryEntrypoint } from '@/config/entrypoint'

import Configurator from './Configurator.vue'

import ConfirmationService from 'primevue/confirmationservice'
import PrimeVue from 'primevue/config'
import Store from '@/store'

// Workspace Packages
import i18n from '@lingoking-node-apps/i18n'
import DesignSystem from '@lingoking-node-apps/design-system'
import Sentry from '@lingoking-node-apps/sentry'

import { setupInterceptors } from '@lingoking-node-apps/api'

// CSS
import '../src/css/tailwind.css'
import '../src/css/icon-lingoking.css'

const mountEl = document.querySelector('#configurator')
const props = mountEl ? { ...mountEl.dataset } : {}

const app = createApp(Configurator, props)

app.use(ConfirmationService)
app.use(i18n)
app.use(PrimeVue, { unstyled: true, pt: DesignSystem })
app.use(Sentry, sentryEntrypoint)
app.use(Store)

// Axios
const refreshTokenCallBack = () => {
  return Store.dispatch('auth/refresh')
}

setupInterceptors(refreshTokenCallBack)

app.mount('#configurator')
