export function reset() {
  if (!window.posthog) {
    console.error("Posthog instance not found");
    return;
  }

  window.posthog.reset();
  window.posthog.unregister("impersonate");
}

export function setCustomer(customer, admin) {
  if (!window.posthog) {
    console.error("Posthog instance not found");
    return;
  }

  window.posthog.unregister("impersonate");

  if (customer) {
    const oldDistinctId = getDistinctId()

    window.posthog.identify(customer.email, {
      email: customer.email,
      name: customer.firstName,
    });

    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.get('posthogDistinctId')) {
      window.posthog.alias(queryParams.get('posthogDistinctId'), customer.email)
    }

    if (oldDistinctId != customer.email) {
      window.posthog.alias(oldDistinctId, customer.email)
    }
  }
}

export function setAdmin(pm, customer) {
  if (!window.posthog) {
    console.error("Posthog instance not found");
    return;
  }

  window.posthog.unregister("impersonate");

  if (pm) {
    window.posthog.identify(pm.email, {
      email: pm.email,
      name: pm.firstName,
      isPm: true,
    });

    if (customer) {
      window.posthog.register({
        impersonate: customer.email,
      });
    }
  }
}

export function getDistinctId() {
  if (!window.posthog) {
    console.error("Posthog instance not found");
    return null;
  }

  return window.posthog.get_distinct_id();
}

export default {
  setCustomer,
  setAdmin,
  getDistinctId,
};
