<template>
  <span :class="{ 'p-input-icon-left': howToTips }">
    <HowToTipModal
      v-if="howToTips"
      ref="howToTipModal"
      :how-to-tips="howToTips"
      @set-preselected-option="setPreSelectedOption"
    />
    <Dropdown
      dataKey="id"
      v-model="optionValue"
      :options="optionsFiltered"
      :placeholder="$t('select_option')"
      optionLabel="label"
      class="w-full md:w-14rem"
      @change="toggleHowToTipModal">
      <template #value="slotProps">
        <div v-if="slotProps.value" class="flex align-items-center w-full">
          <div class="grow truncate">
            {{ slotProps.value.productOption.label }}
          </div>
          <div v-if="isLoading" class="h-6 w-6">
            <ProgressSpinner class="h-full w-full"/>
          </div>
          <Chip v-else-if="getOptionPrice(optionValue)" class="shrink bg-royal-200 text-gray-700">
            {{ getOptionPrice(optionValue) }}
          </Chip>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div class="w-full whitespace-normal flex">
          <div class="flex-grow">
            <div class="flex flex-row space-x-2">
              <p class="text-base">{{ slotProps.option.productOption.label }}</p>
              <span
                v-if="slotProps.option.recommended"
                class="px-1 font-semibold flex items-center text-xs text-white rounded bg-red-500"
              >
                {{ $t('recommended') }}
              </span>
            </div>
            <p class="group-hover:text-blue-200 text-sm text-ellipsis">
              {{ slotProps.option.productOption.description }}
            </p>
          </div>
          <div
            v-if="getOptionPriceDifference(slotProps.option, modelValue)"
            class="flex items-center flex-shrink-0"
          >
            <Chip class="bg-royal-200 text-gray-700">{{ getOptionPriceDifference(slotProps.option, modelValue) }}</Chip>
          </div>
        </div>
      </template>
    </Dropdown>
  </span>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'

import Chip from 'primevue/chip'
import Dropdown from 'primevue/dropdown'
import ProgressSpinner from 'primevue/progressspinner';

import HowToTipModal from '../modals/HowToTipModal'
import {useStore} from "vuex";

const props = defineProps([
  'modelValue',
  'options',
  'error',
  'placeholder',
  'name',
  'label',
  'getOptionPrice',
  'toggleSideOver',
  'calculatedOrder',
  'categoryType',
  'dependingOn',
  'dependencyOption',
  'disabled'
])

const {n, locale} = useI18n()
const store = useStore()

// model
const optionValue = defineModel()

// refs
const optionsFiltered = ref(null)

// How-To-Tips
const howToTipModal = ref()
const howToTips = ref(null)
const howToTipsWarn = ref(false)

onMounted(() => {
  optionsFiltered.value = getOptionsFiltered()

  if (!optionValue.value) {
    setPreSelectedOption(props.options)
  }
})

// computed
const isLoading = computed(() => {
  return store.state.isLoading
})

// hooks
watch(optionValue, () => {
  checkHowToTips()
})

watch(optionsFiltered, (optionsFiltered) => {
  let optionStillAvailable = optionsFiltered.find(
    (optionCombination) => optionCombination === optionValue.value
  )

  if (!optionStillAvailable) {
    setPreSelectedOption(optionsFiltered)
  }
})

watch(() => props.dependencyOption, () => {
  optionsFiltered.value = getOptionsFiltered()
});

const getOptionsFiltered = () => {
  let options = props.options.filter((productOptionCombination) => {
    let category = productOptionCombination.productOption.categories[0]

    if (productOptionCombination.productOption.type === 'radio') {
      return category.categoryType === props.categoryType
    }
  })

  // Filtering Depending On
  if (props.dependingOn) {
    return options.filter((optionCombination) => {
      if (props.dependencyOption && optionCombination.productOption.dependingOn.length > 0) {
        return optionCombination.productOption.dependingOn.includes(
          props.dependencyOption.productOption.name
        )
      }

      return true
    })
  }

  return options
}

const getOptionPriceDifference = (optionCombination, modelValue) => {
  if (modelValue.productOption.id === optionCombination.productOption.id) {
    return
  }

  if (props.calculatedOrder.options) {
    let calculatedOption = props.calculatedOrder.options['hydra:member'].find((option) => {
      return option.productOption === optionCombination.productOption.id
    })

    let calculatedSelectedOption = props.calculatedOrder.options['hydra:member'].find(option => {
      return option.productOption === modelValue.productOption.id
    })

    let total = calculatedOption.productOptionPrice - calculatedSelectedOption.productOptionPrice
    total = total + (total * props.calculatedOrder.vatRate)

    let totalCurrency = n(total, 'currency', locale.value)

    if (total === 0) {
      return
    }

    return total > 0 ? '+' + totalCurrency : totalCurrency
  }
}

const getPreSelectedOption = (options) => {
  let optionsFiltered = options.filter((productOptionCombination) => {
    if (productOptionCombination.productOption.type === 'radio') {
      return (
        productOptionCombination.productOption.categories[0].categoryType === props.categoryType
      )
    }
  })

  return optionsFiltered.find((productOptionCombination) => {
    return productOptionCombination.selected
  })
}

const setPreSelectedOption = (options) => {
  optionValue.value = getPreSelectedOption(options ? options : props.options)
}

const toggleHowToTipModal = () => {
  // showing the modal if the user selects another option (how-to-tips previously saved below):
  if (howToTipsWarn.value === true) {
    setTimeout(function () {
      howToTipModal.value.confirmation()
    }, 1000)

    howToTipsWarn.value = false
  }

  checkHowToTips()
}

const checkHowToTips = () => {
  // if the options has a how-to-tips, store it to be shown if the user navigates to another option:
  if (optionValue.value && optionValue.value.productOption.howToTips[0]) {
    howToTips.value = optionValue.value.productOption.howToTips[0]
    howToTipsWarn.value = true
  }
}
</script>
