export default {
  progressbar: {
    root: {
      class: [
       "overflow-hidden relative border-0 h-3 bg-royal-200",
      ],
    },
    value: ({ props }) => ({
      class: [
        // Flexbox & Overflow & Position
        {
          "absolute flex items-center justify-center overflow-hidden":
            props.mode !== "indeterminate",
        },
        // Colors
        "bg-royal-400",
        // Spacing & Sizing
        "m-0",
        { "h-full w-0": props.mode !== "indeterminate" },
        // Shape
        "border-0",
        // Transitions
        {
          "transition-width duration-1000 ease-in-out":
            props.mode !== "indeterminate",
          "progressbar-value-animate": props.mode == "indeterminate",
        },
        // Before & After (indeterminate)
        {
          "before:absolute before:top-0 before:left-0 before:bottom-0 before:bg-inherit ":
            props.mode == "indeterminate",
          "after:absolute after:top-0 after:left-0 after:bottom-0 after:bg-inherit after:delay-1000":
            props.mode == "indeterminate",
        },
      ],
    }),
    label: {
      class: ["inline-flex", "text-white", "leading-6"],
    },
  },
};
