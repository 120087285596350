<template>
  <div v-if="optionFormType.formType === 'checkbox'">
    <div class="flex items-center">
      <Checkbox
        v-model="checkboxOptionValue"
        :binary="true"
        :inputId="additionalOption.productOptionCombination.productOption.name"
        :name="additionalOption.productOptionCombination.productOption.name"
      />
      <label :for="additionalOption.productOptionCombination.productOption.name" class="ml-2">
        {{ label }}
      </label>
    </div>
  </div>
  <div v-else-if="optionFormType.formType === 'text'">
    <div class="flex flex-col gap-1">
      <label :for="additionalOption.productOptionCombination.productOption.name">
        {{ label }}
      </label>
      <InputText
        v-model="orderOption.productOptionValue"
        :id="additionalOption.productOptionCombination.productOption.name"
        :placeholder="optionFormType.formType.includes('number') ? 0 : 'abcXYZ'"
      />
    </div>
  </div>
  <div v-else-if="optionFormType.formType === 'number'">
    <div class="flex flex-col gap-1">
      <label :for="additionalOption.productOptionCombination.productOption.name">
        {{ label }}
      </label>
      <InputNumber
        v-model="inputNumberValue"
        :id="additionalOption.productOptionCombination.productOption.name"
        :min="optionFormType.min"
        :max="optionFormType.max"
        showButtons
      />
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'

import Checkbox from 'primevue/checkbox'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'

export default {
  name: 'Input',
  props: ['additionalOption', 'optionFormType', 'keyVal', 'label', 'toggleSideOver'],
  components: {
    Checkbox,
    InputNumber,
    InputText
  },
  setup(props) {
    const orderOption = computed(() => {
      return props.additionalOption
    })

    const checkboxOptionValue = computed({
      get() {
        return orderOption.value.productOptionValue === 'true'
      },
      set(value) {
        orderOption.value.productOptionValue = value.toString()
      }
    })

    const inputNumberValue = computed({
      get() {
        if (orderOption.value.productOptionValue) {
          return Number(orderOption.value.productOptionValue)
        }

        return null
      },
      set(value) {
        orderOption.value.productOptionValue = value.toString()
      }
    })

    return {
      orderOption,
      checkboxOptionValue,
      inputNumberValue
    }
  }
}
</script>
