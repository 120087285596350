import {useStore} from "vuex";

export const useOrderPrice = () => {
  const store = useStore()

  const calculateOrderPrice = async (orderToCalculate) => {
    let order = JSON.parse(JSON.stringify(orderToCalculate));

    if (verifyOrderPropertiesToCalculate(order)) {
      let orderToCalculate = {}
      let options = []

      options.push(prepareOrderOption(order.selectedTranslationOption, false))
      options.push(prepareOrderOption(order.selectedDeliveryOption, false))

      order.additionalOptions.forEach(additionalOption => {
        additionalOption.productOptionValue = (typeof (additionalOption.productOptionValue) === 'undefined' || (additionalOption.productOptionValue) === null) ? additionalOption.productOptionValue : additionalOption.productOptionValue.toString()
        options.push(prepareOrderOption(additionalOption.productOptionCombination, additionalOption))
      })

      orderToCalculate.product = store.getters["product/product"]['@id']
      orderToCalculate.quantity = parseInt(order.quantity)
      orderToCalculate.sourceLanguage = order.sourceLanguage['@id']
      orderToCalculate.targetLanguages = order.targetLanguages.map(targetLanguage => { return targetLanguage['@id'] })
      orderToCalculate.options = options

      const orderToEdit = store.getters["order/order"];

      if (orderToEdit) {
        // orderToCalculate.partOfInvoice = orderToEdit.partOfInvoice['@id']
        // orderToCalculate.customer = orderToEdit.partOfInvoice.customer['@id']
      }

      await store.dispatch('order/getCalculatedOrder', orderToCalculate)
    }
  }

  const prepareOrderOption = (productOptionCombination, additionalOption) => {
    let orderOption = {};

    orderOption.productOption = productOptionCombination.productOption['@id']
    orderOption.productOptionCombination = productOptionCombination['@id']

    if (additionalOption) {
      orderOption.productOptionValue = additionalOption.productOptionValue
    }

    return orderOption;
  }

  function verifyOrderPropertiesToCalculate(order) {
    return order.quantity &&
      order.selectedTranslationOption &&
      order.selectedDeliveryOption &&
      order.sourceLanguage &&
      order.targetLanguages.length > 0
  }

  return {
    calculateOrderPrice,
    prepareOrderOption
  }
}

export default useOrderPrice;
