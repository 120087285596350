import {ENTRYPOINT} from "../config/entrypoint";
import api from "./api";

const fetch = (id, options) => {
  return api(new URL(id, ENTRYPOINT).toString(), options).then((response) => response.data)
}

const fetchFile = (url, fileName) => {
  let anchor = document.createElement('a');
  document.body.appendChild(anchor);

  return fetch(url, {responseType: 'blob'})
    .then(blob => {
      let objectUrl = window.URL.createObjectURL(new Blob([blob]));

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    })
}

export { fetch, fetchFile }
