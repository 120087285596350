import TRANSITIONS from './utils/transitions'

export default {
  dropdown: {
    root: ({ props }) => ({
      class: [
        'bg-white font-sans rounded-[10px]',
        'focus-within:ring-1 focus-within:ring-royal-400',
        'cursor-pointer inline-flex relative select-none',
        'transition-colors duration-200 ease-in-out',
        'border hover:border-royal-300',
        {
          'text-neutral-700 border-neutral-600 hover:border-royal-300': !props.disabled,
          'text-neutral-500 border-neutral-400 placeholder:text-neutral-400 select-none pointer-events-none cursor-default': props.disabled,
          'select-none pointer-events-none cursor-default': props.disabled
        },
      ]
    }),
    panel: 'lg:max-w-xs',
    input: ({ props }) => ({
      class: [
        'cursor-pointer self-center block flex flex-auto overflow-hidden text-ellipsis whitespace-nowrap relative',
        'rounded-[10px] border-0',
        'p-3 transition duration-200 appearance-none',
        'focus:outline-none focus:shadow-none',
        { 'pr-7': props.showClear }
      ]
    }),
    trigger: ({ props }) => ({
      class: [
        'flex items-center justify-center shrink-0',
        'bg-transparent w-12 rounded-tr-lg rounded-br-lg',
        {
          'text-neutral-700': !props.disabled,
          'text-neutral-500': props.disabled,
        }
      ]
    }),
    wrapper: ({ props }) => ({
      class: [
        {
          '!border-1 !border-t-0 rounded-b-lg': props.filter,
          '!border-1 rounded-lg': !props.filter,
        },
        'max-h-[200px] overflow-auto border-neutral-700',
        'bg-white text-neutral-700 shadow-lg',
      ]
    }),
    list: {
      class: 'list-none m-0'
    },
    item: ({ context }) => ({
      class: [
        'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
        'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
        {
          'text-neutral-700 hover:text-neutral-700 hover:bg-neutral-100':
            !context.focused && !context.selected,
          'bg-neutral-200 text-neutral-700 hover:text-neutral-700 hover:bg-royal-100':
            context.focused && !context.selected,
          'bg-royal-400 text-neutral-200':
            context.selected,
        }
      ]
    }),
    itemgroup: {
      class: [
        '!border-b !border-neutral-200',
        'm-0 p-2 p-y-3 text-neutral-800 bg-white font-bold text-sm',
        'cursor-auto'
      ]
    },
    header: {
      class: [
        '!border-1 !border-b-neutral-300 !border-neutral-700',
        'p-3 text-neutral-700 bg-neutral-100 mt-0 rounded-tl-lg rounded-tr-lg',
      ]
    },
    filtercontainer: {
      class: 'relative'
    },
    filterinput: {
      class: [
        'border-neutral-400',
        'pr-7 -mr-7',
        'w-full',
        'font-sans text-base text-neutral-800 bg-white py-3 px-3 border border-neutral-300 transition duration-200 rounded-[10px] appearance-none',
        'hover:border-neutral-700 focus:ring-0'
      ]
    },
    filtericon: {
      class: 'inline-block -mt-2 absolute top-1/2 text-neutral-800'
    },
    clearicon: {
      class: 'text-neutral-800 right-12 -mt-2 absolute top-1/2'
    },
    emptyMessage: {
      class: ['px-5 py-3.5']
    },
    transition: TRANSITIONS.overlay
  }
}
