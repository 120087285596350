import {useStore} from "vuex";
import {integer, minValue, required} from "@vuelidate/validators";

export const useOrder = () => {
  const store = useStore()

  const addOrder = (orderId) => {
    return store.dispatch("order/addOrder", orderId)
  }

  const getOrder = (orderId) => {
    return store.dispatch("order/getOrder", orderId)
  }

  const updateOrder = (order) => {
    return store.dispatch("order/updateOrder", order)
  }

  const createOrderOption = (productOptionCombination, productOptionCategory, productOptionValue) => {
    let option = {}

    option.productOption = productOptionCombination.productOption['@id']
    option.productOptionCategory = productOptionCategory
    option.productOptionCombination = productOptionCombination['@id']
    option.selected = true

    option.productType = productOptionCombination.productOption.productType

    if (productOptionValue) {
      option.productOptionValue = productOptionValue
    }

    return option
  }

  const rules = {
    pagesOrWords: {required, integer, minValue: minValue(1)},
    sourceLanguage: {required},
    targetLanguages: {required, targetLanguageSelected},
    selectedTranslationOption: {required, $autoDirty: true},
    selectedDeliveryOption: {required, $autoDirty: true}
  }

  function targetLanguageSelected(targetLanguages) {
    let languageNotSelected = targetLanguages.filter(targetLanguage => {
      return targetLanguage === null
    })

    return languageNotSelected.length === 0
  }

  return {
    addOrder,
    getOrder,
    updateOrder,
    createOrderOption,
    rules
  }
}

export default useOrder;
