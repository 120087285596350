export default {
  chip: {
    root: {
      class: [
        'inline-flex items-center',
        'rounded-[16px] px-3'
      ]
    },
    label: 'leading-6 mt-1.5 mb-1.5',
    icon: 'leading-6 mr-2',
    image: {
      class: ['w-9 h-9 ml-[-0.75rem] mr-2', 'rounded-full']
    },
    removeIcon: {
      class: ['ml-2 rounded-md transition duration-200 ease-in-out', 'cursor-pointer leading-6']
    }
  }
}
