export default {
  steps: {
    root: {
      class: "relative",
    },
    menu: {
      class: "p-0 m-0 list-none flex",
    },
    menuitem: {
      class: [
        "relative flex justify-center flex-1",
        "before:border-t before:border-royal-200 before:w-full before:absolute before:top-1/4 before:left-0 before:transform before:-translate-y-1/2",
      ],
    },
    action: {
      class: [
        "inline-flex flex-col items-center overflow-hidden",
        "transition-shadow rounded-md",
        "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]",
      ],
    },
    step: {
      class: [
        "flex items-center justify-center",
        "text-gray-700 border border-gray-300 w-[2rem] h-[2rem] leading-2rem text-sm z-10 rounded-full",
      ],
    },
    label: {
      class: [
        "block",
        "whitespace-nowrap overflow-hidden text-ellipsis max-w-full",
        "mt-2 text-gray-500",
      ],
    },
  },
};
