import {AuthService, TokenService} from '@lingoking-node-apps/api'

const user = TokenService.getTokenData();
const initialState = user
  ? {status: {loggedIn: true}, authorized: null, userData: null, user}
  : {status: {loggedIn: false}, authorized: null, userData: null, user: null};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({commit}, user) {
      commit('SET_LOADING', true, {root: true})
      commit('SET_ERROR', null, {root: true})

      return AuthService.login(user).then(user => {
          commit('SET_LOADING', false, {root: true})
          commit('loginSuccess', user);

          return Promise.resolve(user);
        },
        error => {
          commit('SET_LOADING', false, {root: true})
          commit('SET_ERROR', error, {root: true})

          commit('loginFailure');

          return Promise.reject(error);
        }
      );
    },
    logout({commit}) {
      commit('SET_LOADING', true, {root: true})
      commit('SET_ERROR', null, {root: true})

      AuthService.logout().then((response) => {
          commit('SET_LOADING', false, {root: true})
          commit('logout');

          return Promise.resolve(response);
        },
        error => {
          commit('SET_ERROR', error, {root: true})
          commit('SET_LOADING', false, {root: true})

          return Promise.reject(error);
        }
      );
    },
    refresh({commit}) {
      commit('SET_LOADING', true, {root: true})
      commit('SET_ERROR', null, {root: true})

      return AuthService.refresh().then(response => {
          commit('SET_LOADING', false, {root: true})

          return Promise.resolve(response);
        },
        error => {
          commit('SET_LOADING', false, {root: true})
          commit('logout');

          if (error.response.status !== 401) {
            commit('SET_ERROR', error, {root: true})
          }

          return Promise.reject(error);
        }
      );
    },
    register({commit}, customer) {
      commit('SET_LOADING', true, {root: true})
      commit('SET_ERROR', null, {root: true})

      return AuthService.register(customer)
        .then(response => {
          commit('registerSuccess');

          return Promise.resolve(response.data);
        })
        .catch(error => {
          commit('SET_ERROR', error, {root: true})
          commit('registerFailure');

          return Promise.reject(error);
        })
        .finally(() => {
          commit('SET_LOADING', false, {root: true})
        })
    },
    update({commit}, customer) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        commit('SET_ERROR', null, {root: true})

        return AuthService.update(customer)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            commit('SET_ERROR', error, {root: true})
            reject(error);
          })
          .finally(() => {
            commit('SET_LOADING', false, {root: true})
          })
      })
    },
    loadAuthorization({commit}, values) {
      commit('SET_LOADING', true, {root: true})
      commit('SET_ERROR', null, {root: true})

      return AuthService.loadAuthorization(values).then(
        response => {
          commit('SET_LOADING', false, {root: true})
          commit('registerAuthorization', response.data.authorized);

          return Promise.resolve(response.data);
        },
        error => {
          commit('SET_ERROR', error, {root: true})
          commit('SET_LOADING', false, {root: true})
          commit('registerAuthorization', false);

          return Promise.reject(error);
        }
      );
    },
    loadCustomerData({commit}, customerId) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})

        return AuthService.loadUserData(customerId).then(
          response => {
            commit('SET_LOADING', false, {root: true})
            commit('registerUserData', response.data);

            resolve(response.data);
          },
          error => {
            commit('SET_LOADING', false, {root: true})

            reject(error);
          }
        );
      })
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    registerAuthorization(state, status) {
      state.authorized = status;
    },
    registerUserData(state, status) {
      state.userData = status;
    }
  }
};
