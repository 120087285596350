const useImage = () => {

  const getImageURL = (img) => {
    if(import.meta.env.MODE === 'production') {
      return '/dist/assets/' + img
    }

    return ('/src/assets/' + img)
  }

  return {
    getImageURL
  }
}

export default useImage;
