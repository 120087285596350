export default {
  tabview: {
    navContainer: () => ({
      class: ['relative']
    }),
    navContent: {
      class: [
        'overflow-y-hidden overscroll-contain overscroll-auto scroll-smooth [&::-webkit-scrollbar]:hidden',
        'text-center'
      ]
    },
    previousButton: {
      class: [
        'absolute z-20 top-0 -left-7 h-full',
        'flex items-center justify-center',
        'bg-transparent text-neutral-500 w-7'
      ]
    },
    nextButton: {
      class: [
        'absolute z-20 top-0 -right-7 h-full',
        'flex items-center justify-center',
        'bg-transparent text-neutral-500 w-7'
      ]
    },
    nav: {
      class: ['w-full inline-flex list-none m-0 p-1 rounded-full', 'bg-white border border-neutral-400']
    },
    inkbar: {
      class: 'hidden'
    },
    tabpanel: {
      header: ({ props }) => ({
        class: [
          'mr-0 w-full',
          {
            'cursor-not-allowed select-none select-none': props?.disabled
          }
        ]
      }),
      headerAction: ({ props, parent, context }) => ({
        class: [
          'relative flex items-center rounded-full',
          'h-full py-3 px-10 m-0',
          'cursor-pointer overflow-hidden select-none text-center text-decoration-none select-none',
          'transition-colors duration-300',
          'hover:bg-royal-200 hover:text-neutral-800',
          'focus:outline-none focus:outline-offset-0',
          {
            'bg-transparent text-neutral-800': parent.state.d_activeIndex !== context.index,
            'bg-royal-400 text-white': parent.state.d_activeIndex === context.index,
            'bg-transparent text-neutral-300': props?.disabled
          }
        ]
      }),
      headerTitle: {
        class: [
          'flex-1 font-sans text-center text-sm lg:text-base font-normal leading-none whitespace-nowrap'
        ]
      },
      content: {
        class: ['pt-4 lg:pt-10 text-neutral-800']
      }
    }
  }
}
