export default {
  skeleton: {
    root: ({ props }) => ({
      class: [
        'overflow-hidden',
        '!mb-2',
        {
          'animate-pulse': props.animation !== 'none'
        },
        // Round
        { 'rounded-full': props.shape === 'circle', 'rounded-md': props.shape !== 'circle' },
        // Colors
        '!bg-gray-300'
      ]
    })
  }
}
