import { usePassThrough } from 'primevue/passthrough'
import Tailwind from 'primevue/passthrough/tailwind'

import RadioButton from './design-components/radiobutton'
import Checkbox from './design-components/checkbox'
import InputText from './design-components/inputtext'
import InputNumber from './design-components/inputnumber'
import InputSwitch from './design-components/inputswitch'
import TextArea from './design-components/textarea'
import Button from './design-components/button'
import Dropdown from './design-components/dropdown'
import Multiselect from './design-components/multiselect'
import Tag from './design-components/tag'
import TagView from './design-components/tagview'
import ProgressSpinner from './design-components/progressspinner'
import ProgressBar from './design-components/progressbar'
import Chips from './design-components/chips'
import Chip from './design-components/chip'
import Carousel from './design-components/carousel'
import Steps from './design-components/steps'
import MenuBar from './design-components/menubar'
import Accordion from './design-components/accordion'
import Badge from './design-components/badge'
import Paginator from './design-components/paginator'
import Message from './design-components/message'
import FileUpload from './design-components/fileupload'
import Skeleton from './design-components/skeleton'
import Dialog from './design-components/dialog'

const CustomDesignSystem = Object.assign({},
  Accordion,
  Checkbox,
  RadioButton,
  Dropdown,
  InputNumber,
  InputText,
  InputSwitch,
  TextArea,
  Button,
  Multiselect,
  Tag,
  TagView,
  ProgressSpinner,
  ProgressBar,
  Chips,
  Chip,
  Carousel,
  Steps,
  MenuBar,
  Badge,
  Paginator,
  Message,
  FileUpload,
  Skeleton,
  Dialog
)

const options = {
  mergeSections: true,
  mergeProps: false
}

const DesignSystem = usePassThrough(Tailwind, CustomDesignSystem, options)

export default DesignSystem
