import {fetch} from '@/composables/useFetch'

export default {
  namespaced: true,
  state: {
    order: null,
    calculatedOrder: {},
  },
  mutations: {
    SET_ORDER(state, order) {
      state.order = order
    },
    SET_CALCULATED_ORDER(state, order) {
      state.calculatedOrder = order
    },
  },
  actions: {
    getCalculatedOrder({commit}, body) {
      return new Promise((resolve, reject) => {
        fetch('/orders/calculate_price', {method: 'POST', data: JSON.stringify(body)})
          .then(response => {
            commit('SET_CALCULATED_ORDER', response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addOrder({commit}, order) {
      return new Promise((resolve, reject) => {
        fetch('/orders', {method: 'POST', data: JSON.stringify(order)})
          .then(response => {
            commit('SET_ORDER', response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getOrder({commit}, id) {
      return new Promise((resolve, reject) => {
        fetch('/orders/' + id)
          .then(response => {
            commit('SET_ORDER', response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateOrder({commit}, order) {
      return new Promise((resolve, reject) => {
        fetch('orders/' + order.id, {method: 'PUT', data: JSON.stringify(order)})
          .then(response => {
            commit('SET_ORDER', response)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    order: state => {
      return state.order
    },
    calculatedOrder: state => {
      return state.calculatedOrder
    },
  }
}
