export default {
  inputnumber: {
    root: 'font-sans w-full inline-flex relative bg-white rounded-[10px]',
    input: {
      root: ({ props, context  }) => {
        return [
          'w-full font-sans bg-white',
          'm-0 transition-colors duration-200 appearance-none',
          'border rounded-[10px]',
          'focus:ring-1 focus:ring-royal-400 outline-none',
          'px-3 py-3',
          {
            'text-neutral-700 border-neutral-600 hover:border-royal-300': !context.disabled,
            'text-neutral-500 !border-neutral-400 placeholder:text-neutral-400 select-none pointer-events-none cursor-default': context.disabled
          },
          {
            'text-lg px-4 py-4': props.size == 'large',
            'text-xs px-2 py-2': props.size == 'small',
            'p-3 text-base': props.size == null
          }
        ]
      }
    },
    buttongroup: ({ props }) => ({
      class: [{
        'flex flex-col absolute w-[2rem] right-0 bottom-0 top-0': props.showButtons && props.buttonLayout == 'stacked',
      }]
    }),
    incrementbutton: ({ props }) => {
      return {
        root: {
          class: [
            'bg-transparent',
            'border-0',
            '!block',
            {
              'rounded-l-none rounded-br-none !p-0 flex-1': props.showButtons && props.buttonLayout == 'stacked',
            },
            { 'text-neutral-700': !props.disabled },
            { 'text-neutral-500': props.disabled },
          ]
        },
      }
    },
    label: '!hidden',
    decrementbutton: ({ props }) => ({
      root: {
        class: [
          'bg-transparent w-full',
          'border-0',
          '!block',
          {
            'rounded-l-none rounded-tr-none !p-0 flex-1': props.showButtons && props.buttonLayout == 'stacked',
          },
          { 'text-neutral-700': !props.disabled },
          { 'text-neutral-500': props.disabled },
        ]
      },
    })
  }
}
