
export default {
    button: {
        root: ({ props, context }) => {
          return {
            class: [
                'transition hover:translate-y-1 duration-300 ease-in-out',
                'font-sans items-center justify-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom',
                'focus:outline-none focus:outline-offset-0',
                'focus:outline-royal-300',
                {
                  'border-1 rounded-full px-6 py-4 !my-[5px] hover:mt-[10px] hover:mb-[0px] shadow-[0px_5px_0px_0px_rgba(0,0,0)] hover:shadow-none focus:shadow-none disabled:shadow-none disabled:border-neutral-300':
                    !props.link && ['primary', 'secondary', 'standard'].includes(props.severity) && !props.text && !props.outlined && !props.plain || (props.pt && ['acceptbutton', 'rejectbutton'].includes(props.pt['data-pc-section'])),
                  'text-white bg-royal-400 border-royal-400':
                    !props.link && props.severity === 'primary' && !props.text && !props.outlined && !props.plain,
                  'text-neutral-800 bg-lime-200 border-lime-200':
                    !props.link && props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
                  'text-royal-400 bg-white border-royal-400 shadow-[0px_5px_0px_0px_rgba(0,29,223)]':
                    !props.link && props.severity === 'standard' && !props.text && !props.outlined && !props.plain || (props.pt && ['acceptbutton'].includes(props.pt['data-pc-section'])),
                  'text-blue-600 bg-transparent border-transparent focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)]':
                    props.link
                },
                {
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(176,185,198,1),0_1px_2px_0_rgba(0,0,0,1)]':
                        props.severity === 'secondary',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(136,234,172,1),0_1px_2px_0_rgba(0,0,0,1)]':
                        props.severity === 'success',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)]':
                        props.severity === 'info',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(250,207,133,1),0_1px_2px_0_rgba(0,0,0,1)]':
                        props.severity === 'warning',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(212,170,251,1),0_1px_2px_0_rgba(0,0,0,1)]':
                        props.severity === 'help',
                    'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(247,162,162,1),0_1px_2px_0_rgba(0,0,0,1)]':
                        props.severity === 'danger'
                },
                {
                  'text-white bg-green-500 border border-green-500 hover:bg-green-600 hover:border-green-600':
                        props.severity === 'success' && !props.text && !props.outlined && !props.plain,
                  'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600':
                        props.severity === 'info' && !props.text && !props.outlined && !props.plain,
                  'text-white bg-orange-500 border border-orange-500 hover:bg-orange-600 hover:border-orange-600':
                        props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
                  'text-white bg-purple-500 border border-purple-500 hover:bg-purple-600 hover:border-purple-600':
                        props.severity === 'help' && !props.text && !props.outlined && !props.plain,
                  'text-white bg-red-500 border border-red-500 hover:bg-red-600 hover:border-red-600':
                        props.severity === 'danger' && !props.text && !props.outlined && !props.plain
                },
                { 'shadow-lg': props.raised },
                { 'rounded-md': !props.rounded && !(props.pt && ['acceptbutton', 'rejectbutton'].includes(props.pt['data-pc-section'])), 'rounded-full': props.rounded || (props.pt && ['acceptbutton', 'rejectbutton'].includes(props.pt['data-pc-section']))},
                {
                  'bg-transparent border-transparent': props.text && !props.plain,
                  'text-blue-500 hover:bg-blue-300/20': props.text && (props.severity === null || props.severity === 'info') && !props.plain,
                  'text-gray-500 hover:bg-gray-300/20': props.text && props.severity === 'secondary' && !props.plain,
                  'text-green-500 hover:bg-green-300/20': props.text && props.severity === 'success' && !props.plain,
                  'text-orange-500 hover:bg-orange-300/20': props.text && props.severity === 'warning' && !props.plain,
                  'text-purple-500 hover:bg-purple-300/20': props.text && props.severity === 'help' && !props.plain,
                  'text-red-500 hover:bg-red-300/20': props.text && props.severity === 'danger' && !props.plain
                },
                { 'shadow-lg': props.raised && props.text },
                {
                  'text-gray-500 hover:bg-gray-300/20': props.plain && props.text,
                  'text-gray-500 border border-gray-500 hover:bg-gray-300/20': props.plain && props.outlined,
                  'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600': props.plain && !props.outlined && !props.text
                },
                {
                  'bg-transparent border': props.outlined && !props.plain,
                  'text-blue-500 border border-blue-500 hover:bg-blue-300/20': props.outlined && (props.severity === null || props.severity === 'info') && !props.plain,
                  'text-gray-500 border border-gray-500 hover:bg-gray-300/20': props.outlined && props.severity === 'secondary' && !props.plain,
                  'text-green-500 border border-green-500 hover:bg-green-300/20': props.outlined && props.severity === 'success' && !props.plain,
                  'text-orange-500 border border-orange-500 hover:bg-orange-300/20': props.outlined && props.severity === 'warning' && !props.plain,
                  'text-purple-500 border border-purple-500 hover:bg-purple-300/20': props.outlined && props.severity === 'help' && !props.plain,
                  'text-red-500 border border-red-500 hover:bg-red-300/20': props.outlined && props.severity === 'danger' && !props.plain
                },
                { 'px-6 py-3 text-base': props.size === null, 'text-xs': props.size === 'xs', 'text-xs py-2 px-6': props.size === 'small', 'text-lg py-3 px-6': props.size === 'large' },
                { 'flex-column': props.iconPos == 'top' || props.iconPos == 'bottom' },
                { '!bg-neutral-300 !text-neutral-400 pointer-events-none cursor-default': context.disabled }
            ]
        }},
      label: ({ props }) => ({
        class: [
          {'flex-1': ['left-end', 'right-end'].includes(props.iconPos) },
          'duration-200',
          {
            'hover:underline': props.link
          },
          { 'invisible w-0': props.label == null }
        ]
      }),
      icon: ({ props }) => ({
        class: [
          'mx-0 text-2xl',
          {
            'mr-2': ['left-end', 'left'].includes(props.iconPos) && props.label != null,
            'ml-2 order-1': ['right-end', 'right'].includes(props.iconPos) && props.label != null,
            'mb-2': props.iconPos == 'top' && props.label != null,
            'mt-2 order-2': props.iconPos == 'bottom' && props.label != null
          }
        ]
      }),
      badge: ({ props }) => ({
        class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }]
      })
    }
}
