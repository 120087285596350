export default {
  badge: {
    root: ({ props: e }) => ({
      class: [
        {
          "font-normal text-sm leading-[1.5rem]": e.size == null,
          "font-bold text-lg leading-[2.25rem]": e.size == "large",
          "font-bold text-2xl leading-[3rem]": e.size == "xlarge",
        },
        "text-center inline-block",
        "p-0 px-1",
        {
          "min-w-[1.5rem] h-[1.5rem]": e.size == null,
          "min-w-[2.25rem] h-[2.25rem]": e.size == "large",
          "min-w-[3rem] h-[3rem]": e.size == "xlarge",
        },
        {
          "rounded-full flex items-center justify-center max-w-min": e.value.length == 1,
          "rounded-[0.71rem] px-2": e.value.length !== 1,
        },
        {
          "text-white bg-primary-500":
            e.severity == null || e.severity == "primary",
          "text-neutral-800 bg-lime-200": e.severity == "secondary",
          "text-white bg-success-300": e.severity == "success",
          "text-white bg-blue-500": e.severity == "info",
          "text-white bg-warning-300": e.severity == "warning",
          "text-neutral-700 bg-lilac-200": e.severity == "help",
          "text-white bg-error-300": e.severity == "danger",
          "text-neutral-700 border border-neutral-700": e.severity == "basic",
        },
      ],
    }),
  },
  "directives.badge": {
    root: ({ context: e }) => ({
      class: [
        "font-bold font-sans",
        "text-xs leading-5",
        "flex items-center justify-center",
        "text-center",
        "absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 origin-top-right",
        "m-0",
        {
          "p-0": e.nogutter || e.dot,
          "px-2": !e.nogutter && !e.dot,
          "min-w-[0.5rem] w-2 h-2": e.dot,
          "min-w-[1.5rem] h-6": !e.dot,
        },
        {
          "rounded-full bg-lime-200": e.nogutter || e.dot,
          "rounded-[10px]": !e.nogutter && !e.dot,
        },
        "text-white",
        {
          "bg-primary-500":
            !e.info &&
            !e.success &&
            !e.warning &&
            !e.danger &&
            !e.help &&
            !e.secondary,
          "bg-lime-200": e.secondary,
          "bg-green-500": e.success,
          "bg-blue-500": e.info,
          "bg-orange-500": e.warning,
          "bg-purple-500": e.help,
          "bg-red-500": e.danger,
        },
      ],
    }),
  },
};
