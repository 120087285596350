export default {
  menubar: {
    root: {
      class: [
        "p-3 bg-white",
        "flex flex-row items-center justify-between relative",
      ],
    },
    menu: ({ props }) => ({
      class: [
        "m-0 sm:p-0 list-none",
        "outline-none",
        "sm:flex items-center flex-wrap sm:flex-row sm:top-auto sm:left-auto sm:relative sm:bg-transparent sm:shadow-none sm:w-auto",
        "flex-col top-full left-0",
        "absolute py-1 bg-white border-0 shadow-md w-full",
        {
          "hidden ": !props?.mobileActive,
          "flex ": props?.mobileActive,
        },
      ],
    }),
    menuitem: {
      class: "sm:relative sm:w-auto w-full static",
    },
    content: ({ props, context }) => ({
      class: [
        " transition-shadow duration-200",
        "",
        { "rounded-md": props.root },
        {
          "text-neutral-700":
            !context.focused && !context.active,
          "bg-neutral-300 text-neutral-700 dark:bg-neutral-800/90":
            context.focused && !context.active,
          "bg-blue-100 text-blue-700 dark:bg-blue-400":
            context.focused && context.active,
          "bg-blue-50 text-blue-700 dark:bg-blue-300":
            !context.focused && context.active,
        },
        {
          "hover:text-neutral-700 hover:bg-neutral-200":
            !context.active,
          "hover:bg-blue-200": context.active,
        },
      ],
    }),
    action: ({ context }) => ({
      class: [
        "select-none",
        "cursor-pointer flex items-center no-underline overflow-hidden relative",
        "py-3 px-5 select-none",
        {
          "pl-9 sm:pl-5": context.level === 1,
          "pl-14 sm:pl-5": context.level === 2,
        },
      ],
    }),
    icon: {
      class: "mr-2",
    },
    submenuicon: ({ props }) => ({
      class: [
        {
          "ml-auto sm:ml-2": props.root,
          "ml-auto": !props.root,
        },
      ],
    }),
    submenu: ({ props }) => ({
      class: [
        "py-1 bg-white border-0  sm:shadow-md sm:w-48",
        "w-full static shadow-none",
        "sm:absolute z-10",
        "m-0 list-none",
        {
          "sm:absolute sm:left-full sm:top-0": props.level > 1,
        },
      ],
    }),
    separator: {
      class: "border-t border-neutral-300 my-1",
    },
    button: {
      class: [
        "flex sm:hidden w-8 h-8 rounded-full text-neutral-600 transition duration-200 ease-in-out",
        "cursor-pointer flex items-center justify-center no-underline",
        "hover:text-neutral-700 hover:bg-neutral-200",
        "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]",
      ],
    },
  },
};
