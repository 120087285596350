import store from '@/store'
import { fetch as fetchAPI } from '@lingoking-node-apps/api'

export const fetch = (url, options) => {
  return new Promise((resolve, reject) => {
    store.commit('SET_LOADING', true, { root: true })
    store.commit('SET_ERROR', null, { root: true })

    fetchAPI(url, options)
      .then(response => {
        store.commit('SET_LOADING', false, { root: true })
        resolve(response)
      })
      .catch(error => {
        store.commit('SET_LOADING', false, { root: true })
        store.commit('SET_ERROR', error, { root: true })
        reject(error)
      })
  })
}

export const fetchEntity = (url, options) => {
  return new Promise((resolve, reject) => {
    store.commit('SET_LOADING', true, { root: true })
    store.commit('SET_ERROR', null, { root: true })

    fetchAPI(url, options)
      .then(response => {
        store.commit('SET_LOADING', false, { root: true })
        resolve(response['hydra:member'])
      })
      .catch(error => {
        store.commit('SET_LOADING', false, { root: true })
        store.commit('SET_ERROR', error, { root: true })
        reject(error)
      })
  })
}
