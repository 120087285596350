import {fetch} from '@/composables/useFetch'

export default {
  namespaced: true,
  state: {
    product: null
  },
  mutations: {
    SET_PRODUCT(state, product) {
      state.product = product
    },
  },
  actions: {
    getProduct({commit}, payload) {
      return new Promise((resolve, reject) => {
        let uri = 'product/order/' + payload.productId

        fetch(uri)
          .then(response => {
            commit('SET_PRODUCT', {...response, en: response})
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setProduct({commit}, product) {
      commit('SET_PRODUCT', product)
    }
  },
  getters: {
    product: state => {
      return state.product
    },
  }
}
