import {computed} from "vue";

export const getScreenManager = () => {

  const isMobile = computed(() => {
    return window.matchMedia('only screen and (max-width: 640px)').matches
  })

  const scrollLock = (lock) => {
    let scrollLockClasses = ['body-scroll-lock']

    scrollLockClasses.forEach(scrollLockClass =>  document.body.classList.toggle(scrollLockClass, lock))
  }

  return {
    isMobile,
    scrollLock
  }
}
