import axios from "axios";
import {ENTRYPOINT} from '../config/entrypoint';

const instance = axios.create({
  withCredentials: true,
  baseURL: ENTRYPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
