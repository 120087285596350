export function getPersistenceType() {
  if (!window.posthog) {
    console.error("Posthog instance not found");

    return;
  }

  return window.posthog.persistence.config.persistence;
}

export function isUsingCookie() {
  return getPersistenceType() == 'cookie';
}


export default {
  getPersistenceType,
  isUsingCookie
};
