import {createI18n} from 'vue-i18n'
import {getCookie, setCookie} from './cookie'
import messages from './translations/locales'
import numberFormats from './translations/numbers'

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })

  return messages
}

function getLocale() {
  const cookieLang = getCookie('lang')
  return cookieLang ? cookieLang : import.meta.env.VITE_APP_I18N_LOCALE || 'en'
}

const fallbackLocale = import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en'


export function saveLocale(locale) {
  setCookie('lang', locale)
}

export default createI18n({
  globalInjection: true,
  legacy: false,
  locale: getLocale(),
  fallbackLocale: fallbackLocale,
  messages: messages ? messages : loadLocaleMessages(),
  numberFormats
})
