<template>
  <form class="space-y-6" @submit.prevent="handleSubmit">
    <div class="space-y-1.5">
      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">
          {{ $t('email') }}
        </label>
        <div class="mt-1">
          <input v-model="username" id="email-address" name="email" type="email" autocomplete="email" required="" class="transition appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lingo-blue focus:border-lingo-blue sm:text-sm" />
        </div>
      </div>
      <div>
        <label for="password" class="block text-sm font-medium text-gray-700">
          {{ $t('password') }}
        </label>
        <div class="mt-1">
          <input v-model="password" id="password" name="password" type="password" autocomplete="current-password"  required="" class="transition appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lingo-blue focus:border-lingo-blue sm:text-sm" />
        </div>
      </div>
    </div>

    <!-- Actions -->
    <div>
      <div class="mb-3">
        <a :href="forgotPasswordLink" target="_blank" class="font-medium text-sm text-lingo-blue-dark hover:text-lingo-blue">
          {{ $t('forgot_password') }}
        </a>
      </div>
      <div>
        <button type="submit" class="transition group relative w-full flex justify-center my-1.5 py-2 px-4 border border-transparent text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
          {{ $t('login') }}
        </button>
        <slot name="register" />
      </div>
    </div>
  </form>
</template>

<script>
import {inject, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

import {SHOP_SERVICE_URL} from "../../config/entrypoint";
import { Posthog } from '@lingoking-node-apps/analytics';

export default {
  name: "LoginForm",
  setup() {
    const { locale } = useI18n()
    const store = useStore()
    const purchaseOrder = inject('purchaseOrder')

    const username = ref(null)
    const password = ref(null)

    const forgotPasswordLink = SHOP_SERVICE_URL.replace(/%.*?%/, locale.value) + '/password-token'

    const handleSubmit = () => {
      store.dispatch("auth/login", {email: username.value, password: password.value})
        .then(() => {
          Posthog.capture.captureSuccessLogin({ invoice: purchaseOrder })
        })
    }

    return {
      username,
      password,
      forgotPasswordLink,
      handleSubmit
    }
  }
}
</script>

<style scoped>

</style>
