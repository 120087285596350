<template>
  <transition name="fade">
    <div v-if="message" class="rounded-md bg-red-50 p-4 mb-3">
      <div class="flex">
        <div class="flex-shrink-0 block">
          <span class="iconlk-info text-xl"></span>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-red-800">
            {{ $t('error_occurred') }}
          </h3>
          <div class="mt-2 text-sm text-red-700">
            <p>
              {{ message }}
            </p>
          </div>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button
              @click="clearMessage()"
              type="button"
              class="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
            >
              <span class="sr-only">Dismiss</span>
              <span class="iconlk-close text-xl"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AlertError',
  props: ['message'],
  emits: ['clear-message'],
  setup(_, emits) {
    const clearMessage = () => {
      emits.emit('clear-message')
    }

    return {
      clearMessage
    }
  }
}
</script>
