export default {
  tag: {
    root: ({ props }) => ({
      class: [
        'font-sans inline-flex items-center justify-center',
        'text-base font-semibold px-2 py-1 ',
        {
          'bg-royal-400': props.severity == 'primary',
          'bg-lime-200': props.severity == 'secondary',
          'bg-green-500 ': props.severity == 'success',
          'bg-blue-500 ': props.severity == 'info',
          'bg-orange-500 ': props.severity == 'warning',
          'bg-red-500 ': props.severity == 'danger'
        },
        {
          'rounded-md': !props.rounded,
          'rounded-full': props.rounded
        }
      ]
    }),
    value: 'leading-6 text-neutral-700 font-normal',
    icon: 'mr-1 text-sm'
  }
}
