import { ref } from "vue";

export function getFeatureFlagPayload(lang) {
  let result = ref(null);

  window.posthog.onFeatureFlags(() => {
    const cartButtonText = window.posthog.getFeatureFlag("cartButtonText");
    if (cartButtonText) {
      result =
        window.posthog.getFeatureFlagPayload("cartButtonText")["add_to_cart"][
          lang
        ];
    }
  });

  return result;
}

export function featureFlagsIsEnabled() {
  if (!window.posthog) {
    console.error("Posthog instance not found");
    return false;
  }

  window.posthog.reloadFeatureFlags();

  return (
    typeof window.posthog.getFeatureFlagPayload("cartButtonText") !==
    "undefined"
  );
}

export default {
  getFeatureFlagPayload,
  featureFlagsIsEnabled,
};
