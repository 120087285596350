import {fetch} from '@/composables/useFetch'

export default {
  namespaced: true,
  state: {
    cart: null,
  },
  mutations: {
    SET_RESET(state) {
      state.cart = null
    },
    SET_CART(state, cart) {
      state.cart = cart
      state.synced = true
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_ERROR(state, error) {
      state.error = error
    }
  },
  actions: {
    addCart({commit}, cart) {
      return new Promise((resolve, reject) => {
        const data = JSON.stringify(cart)

        fetch('carts', {method: 'POST', data})
          .then((response) => {
            commit('SET_CART', response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteCart({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_RESET', null)

        fetch('carts', {method: 'DELETE', data: JSON.stringify({})})
          .then((response) => {
            commit('SET_CART', response)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addOrder({commit}, order) {
      return new Promise((resolve, reject) => {
        const data = JSON.stringify({orders: [order]})

        fetch('carts/add-orders-to-cart', {method: 'POST', data})
          .then((response) => {
            commit('SET_CART', response)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    removeOrder({commit}, order) {
      return new Promise((resolve, reject) => {
        const data = JSON.stringify({tmpId: order.tmpId})

        fetch('carts/remove-order-from-cart', {method: 'POST', data})
          .then((response) => {
            commit('SET_CART', response)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
  getters: {
    cart: state => {
      return state.cart
    },
  }
}
