import TRANSITIONS from "./utils/transitions";

export default {
  multiselect: {
    root: ({ props, state }) => ({
      class: [
        // Display and Position
        "inline-flex",
        "relative",
        // Shape
        "rounded-lg",
        // Color and Background
        "bg-white",
        "border border-neutral-300 dark:border-neutral-700",
        // Transitions
        "transition-all",
        "duration-200",
        // States
        {
          "!border-1 border-neutral-600 border-offset-0 hover:border-royal-300 focus-within:ring-1 focus-within:ring-royal-400":
            (!state.focused && !props.disabled) ||
            (!state.overlayVisible && !props.disabled),
        },
        {
          "!border-1 !border-royal-300 !border-offset-0": state.focused,
        },
        {
          "!border-1 !border-royal-300 !border-offset-0": state.overlayVisible,
        },
        {
          "!border-1 !border-neutral-400 !border-offset-0 cursor-not-allowed":
            props.disabled,
        },
      ],
    }),
    labelContainer: {
      class: "overflow-hidden flex flex-auto items-center cursor-pointer ",
    },
    label: ({ props }) => {
      var _a, _b;
      return {
        class: [
          "leading-6",
          "block ",
          // Spacing
          {
            "p-3": props.display !== "chip",
            "py-3 px-3":
              props.display === "chip" &&
              !(
                (_a = props == null ? void 0 : props.modelValue) != null &&
                _a.length
              ),
            "py-1.5 px-3":
              props.display === "chip" &&
              ((_b = props == null ? void 0 : props.modelValue) == null
                ? void 0
                : _b.length) > 0,
          },
          // Color
          {
            "text-neutral-800": props.modelValue,
            "text-neutral-400 dark:text-neutral-500": !props.modelValue,
          },
          "placeholder:text-neutral-400 dark:placeholder:text-neutral-500",
          // Transitions
          "transition duration-200",
          // Misc
          "overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis",
        ],
      };
    },
    token: {
      class: [
        "inline-flex items-center",
        "py-1.5 px-3 mr-2",
        "rounded-[1.14rem]",
        "bg-neutral-700 text-white",
        "cursor-default",
      ],
    },
    removeTokenIcon: {
      class: [
        "rounded-md leading-6",
        "ml-2",
        "w-4 h-4",
        "transition duration-200 ease-in-out",
        "cursor-pointer",
      ],
    },
    trigger: {
      class: [
        "flex items-center justify-center",
        "shrink-0",
        "bg-transparent",
        "text-neutral-700",
        "w-12",
        "rounded-tr-md",
        "rounded-br-md",
      ],
    },
    panel: {
      class: [
        "absolute top-0 left-0",
        "!border !border-neutral-800",
        "rounded-md",
        "shadow-md",
        "bg-white",
        "overflow-hidden",
        "lg:max-w-0"
      ],
    },
    header: {
      class: [
        "flex items-center justify-between",
        "py-3 px-5",
        "m-0",
        "border-b",
        "rounded-tl-md",
        "rounded-tr-md",
        "text-neutral-700",
        "bg-neutral-100",
        "border-neutral-300",
      ],
    },
    headerCheckboxContainer: {
      class: [
        "relative",
        "inline-flex",
        "align-bottom",
        "w-6",
        "h-6",
        "cursor-pointer",
        "select-none",
      ],
    },
    headerCheckbox: {
      root: {
        class: [
          "relative",
          "inline-flex",
          "align-bottom",
          "w-6",
          "h-6",
          "mr-2",
          "cursor-pointer",
          "select-none",
        ],
      },
      box: ({ props, context }) => ({
        class: [
          // Alignment
          "flex",
          "items-center",
          "justify-center",
          // Size
          "w-6",
          "h-6",
          // Shape
          "rounded-md",
          "border",
          // Colors
          {
            "border-neutral-600 bg-neutral-0":
              !context.checked,
            "border-royal-400 bg-royal-400":
              context.checked,
          },
          // States
          {
            "peer-hover:border-primary-500 dark:peer-hover:border-primary-400":
              !props.disabled && !context.checked,
            "peer-hover:bg-primary-600 dark:peer-hover:bg-primary-300 peer-hover:border-primary-700 dark:peer-hover:border-primary-300":
              !props.disabled && context.checked,
            "peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20":
              !props.disabled,
            "cursor-default opacity-60": props.disabled,
          },
          // Transitions
          "transition-colors",
          "duration-200",
        ],
      }),
      input: {
        class: [
          "peer",
          "w-full ",
          "h-full",
          "absolute",
          "top-0 left-0",
          "z-10",
          "p-0",
          "m-0",
          "opacity-0",
          "rounded-md",
          "outline-none",
          "!border-2 !border-neutral-200",
          "appareance-none",
          "cursor-pointer",
        ],
      },
      icon: {
        class: [
          "text-base leading-none",
          "w-4",
          "h-4",
          "text-white",
          "transition-all",
          "duration-200",
        ],
      },
    },
    itemCheckbox: {
      root: {
        class: [
          "relative",
          "inline-flex",
          "align-bottom",
          "w-6",
          "h-6",
          "mr-2",
          "cursor-pointer",
          "select-none",
        ],
      },
      box: ({ props, context }) => ({
        class: [
          // Alignment
          "flex",
          "items-center",
          "justify-center",
          // Size
          "w-6",
          "h-6",
          // Shape
          "rounded-md",
          "border",
          "group-hover:border-royal-300",
          // Colors
          {
            "border-neutral-600":
              !context.checked,
            "border-primary-500":
              context.checked,
          },
          // States
          {
            "peer-hover:border-primary-500 dark:peer-hover:border-primary-400":
              !props.disabled && !context.checked,
            "peer-hover:bg-primary-600 dark:peer-hover:bg-primary-300 peer-hover:border-primary-700 dark:peer-hover:border-primary-300":
              !props.disabled && context.checked,
            "peer-focus-visible:border-primary-500 dark:peer-focus-visible:border-primary-400 peer-focus-visible:ring-2 peer-focus-visible:ring-primary-400/20 dark:peer-focus-visible:ring-primary-300/20":
              !props.disabled,
            "cursor-default opacity-60": props.disabled,
          },
          // Transitions
          "transition-colors",
          "duration-200",
        ],
      }),
      input: {
        class: [
          "peer",
          "w-full ",
          "h-full",
          "absolute",
          "top-0 left-0",
          "z-10",
          "p-0",
          "m-0",
          "opacity-0",
          "rounded-md",
          "outline-none",
          "border-2 border-neutral-200 dark:border-neutral-700",
          "appareance-none",
          "cursor-pointer",
        ],
      },
      icon: {
        class: [
          "text-base leading-none",
          "w-4",
          "h-4",
          "text-white dark:text-neutral-900",
          "transition-all",
          "duration-200",
        ],
      },
    },
    closeButton: {
      class: [
        "relative",
        "flex items-center justify-center",
        "mr-2",
        "last:mr-0",
        "w-8 h-8",
        "border-0",
        "rounded-full",
        "text-neutral-500",
        "bg-transparent",
        "transition duration-200 ease-in-out",
        "hover:text-neutral-700 dark:hover:text-white/80",
        "hover:bg-neutral-100 dark:hover:bg-neutral-800/80",
        "focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset",
        "focus:ring-primary-400/50 dark:focus:ring-primary-300/50",
        "overflow-hidden",
      ],
    },
    closeButtonIcon: {
      class: "w-4 h-4 inline-block",
    },
    wrapper: {
      class: ["max-h-[200px]", "overflow-auto"],
    },
    list: {
      class: "py-3 list-none m-0",
    },
    item: ({ context, props }) => ({
      class: [
        "group",
        // Font
        "font-normal",
        "leading-none",
        // Flexbox
        "flex items-center",
        // Position
        "relative",
        // Shape
        "border-0",
        "rounded-none",
        // Spacing
        "m-0",
        "py-3 px-5",
        // Color
        {
          "text-natural-700 bg-white hover:bg-royal-100":
            (!context.focused && !context.selected) ||
            (context.focused && !context.selected),
          "text-white bg-royal-400 outline outline-royal-300":
            context.focused && context.selected,
          "text-white bg-royal-400": !context.focused && context.selected,
          "text-natural-400 bg-white": props.disabled,
        },
        // Transitions
        "transition-shadow",
        "duration-200",
        // Misc
        "cursor-pointer",
        "overflow-hidden",
        "whitespace-nowrap",
      ],
    }),
    itemgroup: {
      class: [
        "font-bold",
        "m-0",
        "p-3 px-5",
        "text-neutral-800",
        "bg-neutral-0 dark:bg-neutral-600/80",
        "cursor-auto",
      ],
    },
    filtercontainer: {
      class: "relative w-full mx-2",
    },
    filterinput: {
      class: [
        "font-sans",
        "leading-none",
        "pr-7 py-3 px-3",
        "-mr-7",
        "w-full",
        "placeholder:text-neutral-400 dark:placeholder:text-neutral-500",
        "appearance-none",
        "transition",
        "duration-200",
        "border-neutral-400",
        "text-neutral-800 bg-white py-3 px-3 border border-neutral-300 rounded-lg",
        "focus-within:ring-1 focus-within:ring-royal-400",
        "hover:border-royal-300 focus:outline-none",
      ],
    },
    filtericon: {
      class: ["absolute", "top-1/2 right-3", "-mt-2", "text-neutral-800"],
    },
    clearicon: {
      class: ["text-neutral-800", "absolute", "top-1/2", "right-12", "-mt-2"],
    },
    emptymessage: {
      class: [
        "leading-none",
        "py-3 px-5",
        "text-neutral-800",
        "bg-transparent",
      ],
    },
    transition: {
      enterFromClass: "opacity-0 scale-y-[0.8]",
      enterActiveClass:
        "transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]",
      leaveActiveClass: "transition-opacity duration-100 ease-linear",
      leaveToClass: "opacity-0",
    },
  },



  // multiselect2: {
  //   root: ({ props, state }) => ({
  //     class: [
  //       "relative inline-flex w-full rounded-[10px]",
  //       "bg-white cursor-pointer",
  //       "transition-colors duration-200 ease-in-out",
  //       {
  //         "border-1 border-neutral-600 border-offset-0 hover:border-royal-300":
  //           (!state.focused && !props.disabled) ||
  //           (!state.overlayVisible && !props.disabled),
  //       },
  //       {
  //         "border-1 border-royal-300 border-offset-0": state.focused,
  //       },
  //       {
  //         "border-1 border-royal-300 border-offset-0": state.overlayVisible,
  //       },
  //       {
  //         "border-1 border-neutral-400 border-offset-0 cursor-not-allowed":
  //           props.disabled,
  //       },
  //     ],
  //   }),
  //   labelContainer: {
  //     class: "overflow-hidden flex flex-auto p-3 cursor-pointer",
  //   },
  //   label: ({ props }) => ({
  //     class: [
  //       "block overflow-hidden cursor-pointer text-ellipsis flex-wrap space-y-1",
  //       "transition duration-200",
  //       {
  //         "text-neutral-700 cursor-pointer": !props.disabled,
  //       },
  //       {
  //         "text-neutral-500 cursor-not-allowed": props.disabled,
  //       },
  //     ],
  //   }),
  //   token: {
  //     class: [
  //       "inline-flex items-center py-1 px-2.5 mr-2 rounded-full",
  //       "bg-neutral-700 text-white",
  //       "font-sans text-sm font-normal",
  //       "cursor-pointer",
  //     ],
  //   },
  //   removeTokenIcon: {
  //     class: [
  //       "ml-2",
  //     ],
  //   },
  //   trigger: ({ props, state }) => ({
  //     class: [
  //       "flex items-center justify-center shrink-0",
  //       "bg-transparent w-12 rounded-tr-[10px] rounded-br-[10px]",
  //       "transition-transform duration-300",
  //       {
  //         "text-neutral-700 cursor-pointer": !props.disabled,
  //       },
  //       {
  //         "text-neutral-500 cursor-not-allowed": props.disabled,
  //       },
  //       {
  //         "rotate-180": state.overlayVisible,
  //       },
  //     ],
  //   }),
  //   header: ({ props }) => ({
  //     class: [
  //       "px-4 py-3 mt-0",
  //       "bg-neutral-100",
  //       "border-r border-l border-t border-neutral-700 rounded-tl-[10px] rounded-tr-[10px]",
  //       {
  //         hidden: !props.filter,
  //       },
  //     ],
  //   }),
  //   headerCheckboxContainer: {
  //     class: "hidden",
  //   },
  //   headerCheckbox: {
  //     class: "hidden",
  //   },
  //   closeButton: {
  //     class: "hidden",
  //   },
  //   closeButtonIcon: {
  //     class: "hidden",
  //   },
  //   wrapper: ({ props }) => ({
  //     class: [
  //       "bg-white text-neutral-800 shadow-md overflow-auto",
  //       {
  //         "rounded-[10px]": !props.filter,
  //       },
  //       {
  //         "rounded-b-[10px]": props.filter,
  //       },
  //       {
  //         "border border-t-0 border-neutral-700 rounded-[10px]": !props.filter,
  //       },
  //       {
  //         "border-l border-r border-b border-neutral-700 rounded-b-[10px]":
  //           props.filter,
  //       },
  //     ],
  //   }),
  //   list: {
  //     class: "list-none m-0",
  //   },
  //   panel: {
  //     class: "",
  //   },
  //   item: ({ props, context }) => ({
  //     class: [
  //       "flex items-center space-x-3",
  //       "group relative m-0 px-5 py-3.5",
  //       "font-normal font-sans text-base",
  //       "cursor-pointer overflow-hidden whitespace-nowrap",
  //       "transition-color duration-200",
  //       {
  //         "text-natural-700 bg-white hover:bg-royal-100":
  //           (!context.focused && !context.selected) ||
  //           (context.focused && !context.selected),
  //         "text-white bg-royal-400 outline outline-royal-300":
  //           context.focused && context.selected,
  //         "text-white bg-royal-400": !context.focused && context.selected,
  //         "text-natural-400 bg-white": props.disabled,
  //       },
  //     ],
  //   }),
  //   checkboxContainer: {
  //     class: [
  //       "bg-whiterelative w-6 h-6 inline-flex align-bottom",
  //       "mr-2",
  //       "cursor-pointer select-none",
  //     ],
  //   },
  //   checkbox: ({ context }) => ({
  //     class: [
  //       "group-hover:border-royal-300",
  //       "flex items-center justify-center w-6 h-6",
  //       "border rounded",
  //       "focus:outline-none focus:outline-offset-0",
  //       "transition-colors duration-200",
  //       {
  //         "border-neutral-600 bg-transparent": !context?.selected,
  //         "border-white bg-transparent": context?.selected,
  //       },
  //     ],
  //   }),
  //   checkboxicon: {
  //     class: "w-4 h-4 transition-all duration-200 text-white text-base",
  //   },
  //   itemgroup: {
  //     class: [
  //       "font-normal font-sans text-sm font-semibold",
  //       "m-0 px-5 py-2 text-neutral-800 bg-white",
  //       "border-b border-royal-200",
  //       "cursor-auto",
  //     ],
  //   },
  //   filtercontainer: {
  //     class: "relative",
  //   },
  //   filterinput: {
  //     class: [
  //       "border-neutral-400",
  //       "pr-7 -mr-7",
  //       "w-full",
  //       "font-sans text-base text-neutral-800 bg-white py-3 px-3 border border-neutral-300 transition duration-200 rounded-lg appearance-none",
  //       "hover:border-neutral-700 focus:ring-0",
  //     ],
  //   },
  //   filtericon: {
  //     class: "-mt-2 absolute top-1/2 text-neutral-800",
  //   },
  //   clearicon: {
  //     class: "text-neutral-800 right-12 -mt-2 absolute top-1/2",
  //   },
  //   emptyMessage: {
  //     class: ["px-5 py-3.5"],
  //   },
  //   transition: TRANSITIONS.overlay,
  // },
};
