export default {
  message: {
    root: ({ props }) => ({
      class: [
        // Spacing and Shape
        "my-4 mx-0",
        "rounded-md",
        // "border-solid border-0 border-l-[6px]",
        // Colors
        {
          "bg-blue-100/70": props.severity == "info",
          "bg-royal-100": props.severity == "success",
          "bg-warning-100": props.severity == "warn",
          "bg-red-100/70": props.severity == "error",
        },
        // {
        //   "border-blue-500": props.severity == "info",
        //   "border-green-500": props.severity == "success",
        //   "border-orange-500": props.severity == "warn",
        //   "border-red-500": props.severity == "error",
        // },
        {
          "text-neutral-800": props.severity == "info",
          "text-green-700": props.severity == "success",
          "text-neutral-800": props.severity == "warn",
          "text-red-700": props.severity == "error",
        },
      ],
    }),
    wrapper: {
      class: ["flex", "py-5 px-7"],
    },
    icon: ({ props }) => ({
      class: [
        "w-6 h-6",
        "text-2xl leading-none mr-2 shrink-0",
        props.severity == "info" ? "text-royal-400" : "",
        props.severity == "warn" ? "text-warning-300" : "",
      ],
    }),
    text: {
      class: ["text-base leading-6", "font-medium"],
    },
    button: {
      class: [
        "flex items-center justify-center",
        "w-8 h-8",
        "ml-auto  relative",
        "rounded-full",
        "bg-transparent",
        "transition duration-200 ease-in-out",
        "hover:bg-surface-0/50 dark:hover:bg-surface-0/10",
        "overflow-hidden",
      ],
    },
    transition: {
      enterFromClass: "opacity-0",
      enterActiveClass: "transition-opacity duration-300",
      leaveFromClass: "max-h-40",
      leaveActiveClass: "overflow-hidden transition-all duration-300 ease-in",
      leaveToClass: "max-h-0 opacity-0 !m-0",
    },
  },
};
