import { VueCookieNext } from "vue-cookie-next";

// VueCookieNext.config({
//   domain: window.lingokingConfig.URL_FQDN,
//   expire: "60d",
// });

export function getCookie(cookieName) {
  return VueCookieNext.getCookie(cookieName);
}

export function setCookie(cookieName, value, options = null) {
  VueCookieNext.setCookie(cookieName, value, options);
}
